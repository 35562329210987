import { AreaLevel, RangeLevel } from '../types/enums/spells';
import { Spell } from '../types/spell';

import MaxPortrait from '../assets/sf1/char/sf1_portrait_max.png';
import LowePortrait from '../assets/sf1/char/sf1_portrait_lowe.png';
import TaoPortrait from '../assets/sf1/char/sf1_portrait_tao.png';
import GongPortrait from '../assets/sf1/char/sf1_portrait_gong.png';
import KhrisPortrait from '../assets/sf1/char/sf1_portrait_khris.png';
import AnriPortrait from '../assets/sf1/char/sf1_portrait_anri.png';
import ArthurPortrait from '../assets/sf1/char/sf1_portrait_arthur.png';
import DomingoPortrait from '../assets/sf1/char/sf1_portrait_domingo.png';
import AlefPortrait from '../assets/sf1/char/sf1_portrait_alef.png';
import TorasuPortrait from '../assets/sf1/char/sf1_portrait_torasu.png';
import HanzouPortrait from '../assets/sf1/char/sf1_portrait_hanzou.png';

import EgressIcon from '../assets/sf1/spell/egress.png';
import BlazeIcon from '../assets/sf1/spell/blaze.png';
import FreezeIcon from '../assets/sf1/spell/freeze.png';
import BoltIcon from '../assets/sf1/spell/bolt.png';
import SleepIcon from '../assets/sf1/spell/sleep.png';
import MuddleIcon from '../assets/sf1/spell/muddle.png';
import DispelIcon from '../assets/sf1/spell/dispel.png';
import DesoulIcon from '../assets/sf1/spell/desoul.png';
import HealIcon from '../assets/sf1/spell/heal.png';
import AuraIcon from '../assets/sf1/spell/aura.png';
import DetoxIcon from '../assets/sf1/spell/detox.png';
import QuickIcon from '../assets/sf1/spell/quick.png';
import SlowIcon from '../assets/sf1/spell/slow.png';
import BoostIcon from '../assets/sf1/spell/boost.png';
import ShieldIcon from '../assets/sf1/spell/shield.png';
import DummyIcon from '../assets/sf1/spell/dummy.png';

export const SF1Spells: Spell[] = [
    {
        name: 'Egress',
        icon: EgressIcon,
        level: [
            {
                level: 1,
                cost: 8,
                range: RangeLevel.SELF,
                area: AreaLevel.ALL,
                characters: [{ icon: MaxPortrait, level: 1 }],
                description: 'Warps the player back to the closest priest. This resets the battle',
            },
        ],
    },
    {
        name: 'Heal',
        icon: HealIcon,
        level: [
            {
                level: 1,
                cost: 3,
                range: RangeLevel.ONE,
                area: AreaLevel.ONE,
                characters: [
                    { icon: LowePortrait, level: 1 },
                    { icon: GongPortrait, level: 1 },
                    { icon: KhrisPortrait, level: 1 },
                    { icon: TorasuPortrait, level: 1 },
                ],
                description: 'Heals one Ally for 12-14 HP',
            },
            {
                level: 2,
                cost: 5,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: LowePortrait, level: 7 },
                    { icon: GongPortrait, level: 8 },
                    { icon: KhrisPortrait, level: 5 },
                    { icon: TorasuPortrait, level: 7 },
                ],
                description: 'Heals one Ally for 12-14 HP',
            },
            {
                level: 3,
                cost: 10,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.ONE,
                characters: [
                    { icon: LowePortrait, level: 16 },
                    { icon: GongPortrait, level: 16 },
                    { icon: KhrisPortrait, level: 16 },
                ],
                description: 'Heals one Ally for 24-29 HP',
            },
            {
                level: 4,
                cost: 20,
                range: RangeLevel.ONE,
                area: AreaLevel.ONE,
                characters: [
                    { icon: LowePortrait, level: 22 },
                    { icon: GongPortrait, level: 24 },
                    { icon: KhrisPortrait, level: 21 },
                ],
                description: 'Heals one Ally completely',
            },
        ],
    },
    {
        name: 'Detox',
        icon: DetoxIcon,
        level: [
            {
                level: 1,
                cost: 3,
                range: RangeLevel.ONE,
                area: AreaLevel.ONE,
                characters: [
                    { icon: LowePortrait, level: 4 },
                    { icon: TorasuPortrait, level: 4 },
                ],
                description: 'Cure Poison',
            },
            {
                level: 2,
                cost: 6,
                range: RangeLevel.ONE,
                area: AreaLevel.ONE,
                description: 'Same Effect as Detox 1. This level of Detox is not used in the game',
            },
        ],
    },
    {
        name: 'Aura',
        icon: AuraIcon,
        level: [
            {
                level: 1,
                cost: 7,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.FIVE,
                characters: [
                    { icon: GongPortrait, level: 30 },
                    { icon: KhrisPortrait, level: 20 },
                    { icon: TorasuPortrait, level: 16 },
                ],
                description: 'Heals up to five allies for 12-14',
            },
            {
                level: 2,
                cost: 11,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.FOURTEEN,
                characters: [
                    { icon: KhrisPortrait, level: 28 },
                    { icon: TorasuPortrait, level: 20 },
                ],
                description: 'Heals up to thirteen allies for 12-14',
            },
            {
                level: 3,
                cost: 15,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.FOURTEEN,
                characters: [{ icon: TorasuPortrait, level: 24 }],
                description: 'Heals up to thirteen allies for 24-29',
            },
            {
                level: 4,
                cost: 18,
                range: RangeLevel.SELF,
                area: AreaLevel.ALL,
                characters: [{ icon: TorasuPortrait, level: 28 }],
                description: 'Heals all force members for 36-44',
            },
        ],
    },
    {
        name: 'Slow',
        icon: SlowIcon,
        level: [
            {
                level: 1,
                cost: 5,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: LowePortrait, level: 10 },
                    { icon: KhrisPortrait, level: 8 },
                ],
                description: 'Reduces the Defense and Speed of an enemy by 10 for 2-3 turns',
            },
            {
                level: 2,
                cost: 20,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.FIVE,
                characters: [{ icon: LowePortrait, level: 19 }],
                description: 'Reduces the Defense and Speed of up to 5 enemies by 10 for 2-3 turns',
            },
        ],
    },
    {
        name: 'Quick',
        icon: QuickIcon,
        level: [
            {
                level: 1,
                cost: 5,
                range: RangeLevel.ONE,
                area: AreaLevel.ONE,
                characters: [
                    { icon: LowePortrait, level: 13 },
                    { icon: KhrisPortrait, level: 12 },
                ],
                description: 'Increases Defense and Speed of one Ally by 10 for 2-3 turns',
            },
            {
                level: 2,
                cost: 16,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.FIVE,
                characters: [{ icon: LowePortrait, level: 25 }],
                description: 'Increases Defense and Speed of up to 5 Allies by 10 for 2-3 turns',
            },
        ],
    },
    {
        name: 'Boost',
        icon: BoostIcon,
        level: [
            {
                level: 1,
                cost: 15,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.ONE,
                characters: [
                    { icon: TaoPortrait, level: 27 },
                    { icon: DomingoPortrait, level: 20 },
                ],
                description: 'Increases Attack of one Ally by 15 for 2-3 turns',
            },
        ],
    },
    {
        name: 'Shield',
        icon: ShieldIcon,
        level: [
            {
                level: 1,
                cost: 5,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: TorasuPortrait, level: 12 },
                    { icon: HanzouPortrait, level: 15 },
                ],
                description: 'Makes one ally immune to magic attacks for 2-3 turns',
            },
        ],
    },

    {
        name: 'Blaze',
        icon: BlazeIcon,
        level: [
            {
                level: 1,
                cost: 2,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: TaoPortrait, level: 1 },
                    { icon: AnriPortrait, level: 1 },
                    { icon: AlefPortrait, level: 1 },
                    { icon: ArthurPortrait, level: 15 },
                ],
                description: 'Deals 6-7 fire damage to one enemy. Crit Chance: 4%',
            },
            {
                level: 2,
                cost: 5,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.FIVE,
                characters: [
                    { icon: TaoPortrait, level: 4 },
                    { icon: AnriPortrait, level: 8 },
                    { icon: AlefPortrait, level: 6 },
                ],
                description: 'Deals 8-9 fire damage up to five enemies. Crit Chance: 4%',
            },
            {
                level: 3,
                cost: 8,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.FIVE,
                characters: [{ icon: TaoPortrait, level: 12 }],
                description: 'Deals 12-14 fire damage up to five enemies. Crit Chance: 4%',
            },
            {
                level: 4,
                cost: 8,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [{ icon: TaoPortrait, level: 20 }],
                description: 'Deals 32-39 fire damage to one enemy. Crit Chance: 4%',
            },
        ],
    },
    {
        name: 'Freeze',
        icon: FreezeIcon,
        level: [
            {
                level: 1,
                cost: 3,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: AnriPortrait, level: 5 },
                    { icon: ArthurPortrait, level: 20 },
                    { icon: DomingoPortrait, level: 1 },
                    { icon: AlefPortrait, level: 3 },
                ],
                description: 'Deals 8-9 ice damage to one enemy. Crit Chance: 6%',
            },
            {
                level: 2,
                cost: 7,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.FIVE,
                characters: [
                    { icon: AnriPortrait, level: 13 },
                    { icon: DomingoPortrait, level: 4 },
                    { icon: AlefPortrait, level: 9 },
                ],
                description: 'Deals 9-11 ice damage up to five enemies. Crit Chance: 6%',
            },
            {
                level: 3,
                cost: 10,
                range: RangeLevel.TWOTOTHREE,
                area: AreaLevel.FIVE,
                characters: [
                    { icon: AnriPortrait, level: 16 },
                    { icon: DomingoPortrait, level: 13 },
                ],
                description: 'Deals 14-17 ice damage up to five enemies. Crit Chance: 6%',
            },
            {
                level: 4,
                cost: 10,
                range: RangeLevel.TWOTOTHREE,
                area: AreaLevel.ONE,
                characters: [
                    { icon: AnriPortrait, level: 23 },
                    { icon: DomingoPortrait, level: 26 },
                ],
                description: 'Deals 40-49 ice damage to one enemy. Crit Chance: 6%',
            },
        ],
    },
    {
        name: 'Bolt',
        icon: BoltIcon,
        level: [
            {
                level: 1,
                cost: 8,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.FIVE,
                characters: [
                    { icon: AnriPortrait, level: 19 },
                    { icon: ArthurPortrait, level: 25 },
                    { icon: AlefPortrait, level: 12 },
                ],
                description: 'Deals 11-13 electric damage up to five enemies. Crit Chance: 3%',
            },
            {
                level: 2,
                cost: 15,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.FOURTEEN,
                characters: [
                    { icon: AnriPortrait, level: 26 },
                    { icon: AlefPortrait, level: 17 },
                ],
                description: 'Deals 12-15 electric damage up to thirteen enemies. Crit Chance: 3%',
            },
            {
                level: 3,
                cost: 20,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.FOURTEEN,
                characters: [{ icon: AlefPortrait, level: 22 }],
                description: 'Deals 20-24 electric damage up to thirteen enemies. Crit Chance: 3%',
            },
            {
                level: 4,
                cost: 20,
                range: RangeLevel.ONETOTHREE,
                area: AreaLevel.ONE,
                characters: [{ icon: AlefPortrait, level: 23 }],
                description: 'Deals 48-59 electric damage to one enemy. Crit Chance: 3%',
            },
        ],
    },
    {
        name: 'Dispel',
        icon: DispelIcon,
        level: [
            {
                level: 1,
                cost: 5,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: TaoPortrait, level: 16 },
                    { icon: HanzouPortrait, level: 4 },
                ],
                description: 'Chance to prevent one enemy from casting spells for 2-3 turns',
            },
        ],
    },
    {
        name: 'Desoul',
        icon: DesoulIcon,
        level: [
            {
                level: 1,
                cost: 8,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: DomingoPortrait, level: 17 },
                    { icon: AlefPortrait, level: 26 },
                    { icon: HanzouPortrait, level: 7 },
                ],
                description: 'Chance to instantly kill one enemy',
            },
            {
                level: 2,
                cost: 15,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.FIVE,
                description: 'Chance to instantly kill up to five enemies',
            },
        ],
    },
    {
        name: 'Muddle',
        icon: MuddleIcon,
        level: [
            {
                level: 1,
                cost: 6,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: AnriPortrait, level: 10 },
                    { icon: DomingoPortrait, level: 7 },
                ],
                description: 'Chance to confuse one enemy for 2-3 turns. Confusion applied by this spell does not work',
            },
        ],
    },
    {
        name: 'Sleep',
        icon: SleepIcon,
        level: [
            {
                level: 1,
                cost: 6,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                characters: [
                    { icon: TaoPortrait, level: 8 },
                    { icon: HanzouPortrait, level: 1 },
                ],
                description: 'Chance to put one enemy to sleep',
            },
        ],
    },
    {
        name: 'Dummy',
        icon: DummyIcon,
        level: [
            {
                level: 1,
                cost: 20,
                range: RangeLevel.ONETOTWO,
                area: AreaLevel.ONE,
                description: 'Same effect as Sleep. This spell is not used in the game.',
            },
        ],
    },
];
