import React, { ReactElement } from 'react';

import Container from '../components/common/container';
import useLocale from '../hooks/use-locale';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';
import GlitchesArt from '../assets/sf1/glitches_art.png';

export default function GlitchesPage(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='mx-auto flex flex-col items-center gap-8'>
            <div className='mx-auto flex w-full items-center justify-center gap-8 lg:w-4/5'>
                <Container disablePadding title={'Glitches in Shining Force 1'}>
                    <div className='flex flex-col items-center lg:flex-row lg:space-x-5'>
                        <img className='w-80' src={GlitchesArt} alt='Max counting Dark Dwarves' />
                        <p className='p-4 text-base text-dark-font xl:text-lg'>
                            Shining Force is the proud parent of a plethora of glitches and bugs that made it into the
                            final build of the game. Some things work differently as intended, some things just don't
                            work period. Some issues have only small impact, but some of them change how we play the
                            game fundamentally. Check out these Guides detailing the unsavory parts of the game's code!
                        </p>
                    </div>
                </Container>
            </div>
            <div className='w-full lg:w-4/5'>
                <Container disablePadding title={`Guides related to Glitches`}>
                    <GuideBlock type={GuideType.GENERAL} topic='Glitches' />
                </Container>
            </div>
        </div>
    );
}
