import React, { ReactElement, useEffect } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';

import { asyncInvoke } from '../utils/function';
import { convertToTimeString } from '../utils/timestring';

import Container from '../components/common/container';
import useGuides from '../hooks/use-guides';

export default function ChapterPage(): ReactElement {
    const params = useParams();
    const { guide, fetchGuideById, isLoading } = useGuides();

    useEffect(() => {
        if (params.id !== undefined) {
            asyncInvoke(fetchGuideById(params.id));
        }
    }, []);

    function createThumbnail(thumbnailId: string | undefined): string | undefined {
        if (!thumbnailId) {
            return undefined;
        }

        return `https://cms.shiningforce-sr.com/assets/${thumbnailId}`;
    }

    return (
        <div>
            <div className='mb-8 lg:hidden'>
                <div className='overflow-hidden rounded-2xl bg-gradient-to-b from-pink-700 to-red-900 p-4'>
                    <p className='text-white'>
                        Some Guides may not look properly on mobile as they're made mostly with desktop monitors in
                        mind. We'll do out best to fix visual errors, but advice to use this site on desktop in any
                        case.
                    </p>
                </div>
            </div>
            <div className='w-full'>
                {isLoading ? (
                    <p>Loading</p>
                ) : (
                    <>
                        {guide !== undefined && (
                            <div className='mx-auto w-full xl:w-4/5'>
                                <Container
                                    title={guide.title}
                                    bigHeader
                                    disablePadding
                                    thumbnail={createThumbnail(guide.thumbnail?.id)}
                                >
                                    <div className='flex items-center justify-between bg-[#00000050] px-8 py-1'>
                                        <p className='text-xs text-gray-400'>
                                            Authored by:{' '}
                                            <span className='text-lg font-bold text-green-400'>
                                                {guide.user_created.first_name}
                                            </span>
                                        </p>
                                        <div className='flex gap-8'>
                                            <p className='text-xs text-gray-400'>
                                                Created:{' '}
                                                <span className='text-base text-white'>
                                                    {convertToTimeString(guide.date_created)}
                                                </span>
                                            </p>
                                            <p className='text-xs text-gray-400'>
                                                Updated:{' '}
                                                <span className='text-base text-white'>
                                                    {convertToTimeString(guide.date_created)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='guide-content -mb-8 border-t border-sf-blue-medium px-8 py-4'>
                                        {parse(guide.content)}
                                    </div>
                                </Container>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
