import React, { ReactElement } from 'react';
import GameButton from '../../../assets/game_button.png';

type Props = {
    expanded: boolean;
    onClick: () => void;
};

export default function SelectGameButton(props: Props): ReactElement {
    const { expanded, onClick } = props;

    return (
        <div
            onClick={onClick}
            className='hover:border-bright-text group absolute left-1/2 -translate-x-1/2 cursor-pointer rounded-full border-[1px] border-gray-700 px-4 py-2 duration-300'
        >
            <div className='absolute left-0 top-0 h-full w-full rounded-full bg-black opacity-20 duration-300 group-hover:bg-sf-blue-medium' />
            <div className='flex h-6 items-center gap-2'>
                <img className='w-5' src={GameButton} alt='Shining Force Cartridge Icon' />
                <p className='text-md group-hover:text-bright-text hidden text-white transition duration-300 lg:block'>
                    Select a game
                </p>
            </div>
        </div>
    );
}
