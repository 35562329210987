import Ch1Thumbnail from '../assets/sf1/ch/ch1.png';
import Ch2Thumbnail from '../assets/sf1/ch/ch2.png';
import Ch3Thumbnail from '../assets/sf1/ch/ch3.png';
import Ch4Thumbnail from '../assets/sf1/ch/ch4.png';
import Ch5Thumbnail from '../assets/sf1/ch/ch5.png';
import Ch6Thumbnail from '../assets/sf1/ch/ch6.png';
import Ch7Thumbnail from '../assets/sf1/ch/ch7.png';
import Ch8Thumbnail from '../assets/sf1/ch/ch8.png';
import { Shop } from '../types/enums/items';

import Ch11 from '../assets/sf1/battle/1-1.png';
import Ch12 from '../assets/sf1/battle/1-2.png';
import Ch13 from '../assets/sf1/battle/1-3.png';
import Ch14 from '../assets/sf1/battle/1-4.png';

import Ch21 from '../assets/sf1/battle/2-1.png';
import Ch22 from '../assets/sf1/battle/2-2.png';
import Ch23 from '../assets/sf1/battle/2-3.png';
import Ch24 from '../assets/sf1/battle/2-4.png';

import Ch31 from '../assets/sf1/battle/3-1.png';
import Ch32 from '../assets/sf1/battle/3-2.png';
import Ch33 from '../assets/sf1/battle/3-3.png';

import Ch41 from '../assets/sf1/battle/4-1.png';
import Ch42 from '../assets/sf1/battle/4-2.png';
import Ch43 from '../assets/sf1/battle/4-3.png';

import Ch51 from '../assets/sf1/battle/5-1.png';
import Ch52 from '../assets/sf1/battle/5-2.png';
import Ch53 from '../assets/sf1/battle/5-3.png';

import Ch61 from '../assets/sf1/battle/6-1.png';
import Ch62 from '../assets/sf1/battle/6-2.png';
import Ch63 from '../assets/sf1/battle/6-3.png';
import Ch64 from '../assets/sf1/battle/6-4.png';

import Ch71 from '../assets/sf1/battle/7-1.png';
import Ch72 from '../assets/sf1/battle/7-2.png';
import Ch73 from '../assets/sf1/battle/7-3.png';
import Ch74 from '../assets/sf1/battle/7-4.png';

import Ch81 from '../assets/sf1/battle/8-1.png';
import Ch82 from '../assets/sf1/battle/8-2.png';
import Ch83 from '../assets/sf1/battle/8-3.png';
import Ch84 from '../assets/sf1/battle/8-4.png';
import Ch85 from '../assets/sf1/battle/8-5.png';

export const SF1Chapters = [
    {
        id: 'Chapter1',
        name: 'Chapter 1',
        img: Ch1Thumbnail,
        battles: [
            { name: '1-1', img: Ch11 },
            { name: '1-2', img: Ch12 },
            { name: '1-3', img: Ch13 },
            { name: '1-4', img: Ch14 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.GUARDIANA, Shop.ALTERONE],
    },
    {
        id: 'Chapter2',
        name: 'Chapter 2',
        img: Ch2Thumbnail,
        battles: [
            { name: '2-1', img: Ch21 },
            { name: '2-2', img: Ch22 },
            { name: '2-3', img: Ch23 },
            { name: '2-4', img: Ch24 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.RINDO],
    },
    {
        id: 'Chapter3',
        name: 'Chapter 3',
        img: Ch3Thumbnail,
        battles: [
            { name: '3-1', img: Ch31 },
            { name: '3-2', img: Ch32 },
            { name: '3-3', img: Ch33 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.BUSTOKE],
    },
    {
        id: 'Chapter4',
        name: 'Chapter 4',
        img: Ch4Thumbnail,
        battles: [
            { name: '4-1', img: Ch41 },
            { name: '4-2', img: Ch42 },
            { name: '4-3', img: Ch43 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.PAO, Shop.URANBATOL],
    },
    {
        id: 'Chapter5',
        name: 'Chapter 5',
        img: Ch5Thumbnail,
        battles: [
            { name: '5-1', img: Ch51 },
            { name: '5-2', img: Ch52 },
            { name: '5-3', img: Ch53 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.WARAL],
    },
    {
        id: 'Chapter6',
        name: 'Chapter 6',
        img: Ch6Thumbnail,
        battles: [
            { name: '6-1', img: Ch61 },
            { name: '6-2', img: Ch62 },
            { name: '6-3', img: Ch63 },
            { name: '6-4', img: Ch64 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.RUDO, Shop.DRAGONIA],
    },
    {
        id: 'Chapter7',
        name: 'Chapter 7',
        img: Ch7Thumbnail,
        battles: [
            { name: '7-1', img: Ch71 },
            { name: '7-2', img: Ch72 },
            { name: '7-3', img: Ch73 },
            { name: '7-4', img: Ch74 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.PROMPT],
    },
    {
        id: 'Chapter8',
        name: 'Chapter 8',
        img: Ch8Thumbnail,
        battles: [
            { name: '8-1', img: Ch81 },
            { name: '8-2', img: Ch82 },
            { name: '8-3', img: Ch83 },
            { name: '8-4', img: Ch84 },
            { name: '8-5', img: Ch85 },
        ],
        description:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        shops: [Shop.RUNEFAUST],
    },
];
