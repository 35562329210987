import React, { ReactElement } from 'react';

import Container from '../components/common/container';
import useLocale from '../hooks/use-locale';
import ShiningFriendsBanner from '../assets/shining_friends_banner.jpg';
import ShiningFriendsPhoto from '../assets/shining-friends.jpg';
import ShiningForceRelay from '../assets/shining_force_relay.jpg';
import JoinDiscord from '../assets/discord-join-button.png';

export default function ShiningFriendsPage(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='mx-auto w-full lg:w-4/5'>
            <Container disablePadding title='The Shining Friends Community'>
                <img className='w-full' src={ShiningFriendsBanner} alt='Shining Friends Banner' />
                <div className='p-8 text-lg text-light-font'>
                    <p className='mb-8'>
                        Speedrunning games in the Shining Series emerged in the western media space around 2013-2014,
                        where the first runs were uploaded and lots of people started to have a shared interest in
                        working and improving the run. A lot of of work was done on Shining Force 2 and Shining Force 1
                        improvements followed shortly also by a different subset of the community respectively.
                    </p>
                    <div className='mb-8 flex items-center space-x-8'>
                        <p className='w-2/5'>
                            Once multiple people started to collaborate and shape the run together, a Skype group was
                            opened to bring all runners together. It was called the Shining Friends. Over the next
                            couple years both games Shining Force 1 & 2 would see tremendous improvements, cutting the
                            run by multiple hours. Other tactical Shining entires such as Shining Force CD and the
                            Gaiden games would also see their first few runs and optimizations, even when the biggest
                            focus remained on Shining Force 1 & 2.
                        </p>
                        <img
                            className='w-3/5'
                            src={ShiningFriendsPhoto}
                            alt='Shining Friends performing at RPGLimitBreak'
                        />
                    </div>
                    <p className='mb-8'>
                        During that time the Skype group also naturally moved over to Discord and organized themselves
                        there. While the Skype group was private and so was the Discord server initially, a couple of
                        invite links started to spread and it saw a huge influx of people who didn't necessarily run the
                        game but shared a deep passion for these old games. Especially today, the server has become the
                        primary way to get connected to the community and to get started in speedrunning the game. All
                        the runners are very helpful and answer questions, but people are also able to share their
                        attempts and live streams, bringing the community closer to them.
                    </p>
                    <div className='mb-8 flex items-center space-x-8'>
                        <img
                            className='w-3/5'
                            src={ShiningForceRelay}
                            alt='Screenshot of the Shining Force Relay Stream on RPGLimitBreak'
                        />
                        <p className='w-2/5'>
                            In 2023 the server was structured to better accomodate both speedrun and casual interest in
                            the game. As of right now it's home for people who like to share their playthroughs,
                            interesting mods and all things Shining Force while still being primarily a platform for
                            discussing and working on the speedruns of these games. We also do community races and
                            organize Shining Force SR from there. It's a lovely community whose love for these Shining
                            Force games run for 30 years.
                        </p>
                    </div>
                    <p className='mb-8 rounded-2xl bg-[#454FBF] p-4 font-bold text-white'>
                        If you are interested in Shining Force speedruns, come and join us, we are very happy to see new
                        people who want to engage with the speedrun in any way and will help you out wherever we can.
                        All of the content you find here on Shining Force SR are created by the very same people. So if
                        you still have questions, don't hesitate to ask.
                    </p>
                    <div className='flex justify-center'>
                        <a href='https://discord.gg/RVhZwpyRRc' target='_blank' rel='noreferrer'>
                            <div className='rounded-2xl bg-[#454FBF] p-4'>
                                <img className='w-48' src={JoinDiscord} alt='join discord cta' />
                            </div>
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    );
}
