import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export default function BackButton(): ReactElement {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => navigate(-1)}
            className='pointer-events-none sticky top-10 inline-block -translate-x-32 cursor-pointer overflow-hidden rounded-2xl bg-gradient-to-b from-sf-blue-highlight to-sf-blue-medium p-px opacity-0 xl:pointer-events-auto xl:opacity-100'
        >
            <div className='rounded-2xl bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-sf-blue-medium to-sf-blue-dark'>
                <div className='relative flex w-full items-center rounded-t-2xl bg-[#2b49ff20] p-3 xl:h-[60px]'>
                    <p className='font-title uppercase text-white lg:text-base xl:text-xl'>{`< Back`}</p>
                </div>
            </div>
        </div>
    );
}
