import React, { ReactElement, useEffect } from 'react';
import useLocale from '../hooks/use-locale';
import { Link } from 'react-router-dom';

import { convertToTimeString } from '../utils/timestring';
import { asyncInvoke } from '../utils/function';
import useGuides from '../hooks/use-guides';

import Container from '../components/common/container';
import GuideEntry from '../components/common/guide-entry';
import Portrait from '../components/common/portrait';

import TopicSelect from '../assets/topic-select.png';
import { SF1Characters } from '../data/char';
import { SF1Chapters } from '../data/chapter';
import BeginnerBanner from '../assets/sf1/beginner.png';
import ItemsBanner from '../assets/sf1/items.png';
import EnemiesBanner from '../assets/sf1/enemies.png';
import SpellsBanner from '../assets/sf1/spells.png';
import MechanicsBanner from '../assets/sf1/mechanics.png';
import GlitchesBanner from '../assets/sf1/glitches.png';
import MiscBanner from '../assets/sf1/misc.png';

import SF1Banner from '../assets/sf1/sf1-title.png';
import Leaderboard from '../components/common/leaderboard';

export default function ShiningForce1Page(): ReactElement {
    const { getText } = useLocale();
    const { guides, fetchNewestGuides, isLoading } = useGuides();

    useEffect(asyncInvoke(fetchNewestGuides), []);

    return (
        <div className='relative'>
            <div className='mb-8 flex w-full flex-col gap-8 lg:flex-row'>
                <div className='flex w-full flex-col gap-8 lg:w-4/5'>
                    <Container title={getText('shining-force-1-page.welcome-block.title')} thumbnail={SF1Banner}>
                        <div className='flex flex-col gap-5 lg:flex-row'>
                            <img
                                className='-m-5 w-full lg:w-72'
                                src={TopicSelect}
                                alt='screenshot of selecting a topic'
                            />
                            <div>
                                <p className='my-5 text-justify text-sm text-dark-font lg:mb-5 lg:pl-5 lg:text-base xl:text-lg'>
                                    {getText('shining-force-1-page.welcome-block.copy1')}
                                </p>
                                <p className='text-justify text-sm text-green-100 lg:pl-5 lg:text-base xl:text-lg'>
                                    {getText('shining-force-1-page.welcome-block.copy2')}
                                </p>
                            </div>
                        </div>
                    </Container>
                    <Container disablePadding title='Newest-Guides'>
                        <div className='min-h-[240px]'>
                            {isLoading ? (
                                <p>Loading</p>
                            ) : (
                                guides.map((guide) => (
                                    <GuideEntry
                                        id={guide.id}
                                        title={guide.title}
                                        author={guide.user_created.first_name}
                                        thumbnail={guide.thumbnail?.id}
                                        createdAt={convertToTimeString(guide.date_created)}
                                        updatedAt={
                                            guide.date_updated
                                                ? convertToTimeString(guide.date_updated)
                                                : convertToTimeString(guide.date_created)
                                        }
                                    />
                                ))
                            )}
                        </div>
                    </Container>
                </div>
                <div className='flex w-full items-start lg:w-1/3'>
                    <Leaderboard />
                </div>
            </div>
            <div className='mb-8 flex w-full flex-col items-start gap-8 lg:flex-row'>
                <Container width='w-full lg:w-3/5' title='General Topics'>
                    <div className='flex flex-row flex-wrap justify-center gap-4'>
                        <Link className='w-full lg:w-[48%]' to='/shining-force-1/beginner'>
                            <img className='sf-border w-full' src={BeginnerBanner} alt='' />
                        </Link>
                        <Link className='w-full lg:w-[48%]' to='/shining-force-1/items'>
                            <img className='sf-border w-full' src={ItemsBanner} alt='' />
                        </Link>
                        <Link className='w-full lg:w-[48%]' to='/shining-force-1/spells'>
                            <img className='sf-border w-full' src={SpellsBanner} alt='' />
                        </Link>
                        <Link className='w-full lg:w-[48%]' to='/shining-force-1/enemies'>
                            <img className='sf-border w-full' src={EnemiesBanner} alt='' />
                        </Link>
                        <Link className='w-full lg:w-[48%]' to='/shining-force-1/mechanics'>
                            <img className='sf-border w-full' src={MechanicsBanner} alt='' />
                        </Link>
                        <Link className='w-full lg:w-[48%]' to='/shining-force-1/glitches'>
                            <img className='sf-border w-full' src={GlitchesBanner} alt='' />
                        </Link>
                        <Link className='w-full lg:w-[48%]' to='/shining-force-1/other'>
                            <img className='sf-border w-full' src={MiscBanner} alt='' />
                        </Link>
                    </div>
                </Container>
                <Container width='w-full lg:w-2/5' title='Chapters'>
                    <div className='flex flex-wrap justify-center gap-8 pb-4'>
                        {SF1Chapters.map((chapter) => (
                            <Link to={`/shining-force-1/chapter/${chapter.id}`}>
                                <div className='w-32 lg:w-36'>
                                    <Portrait name={chapter.name} portrait={chapter.img} />
                                </div>
                            </Link>
                        ))}
                    </div>
                </Container>
            </div>
            <Container title='Characters'>
                <div className='flex flex-wrap justify-center gap-4 pb-4 lg:gap-10'>
                    {SF1Characters.map((character) => (
                        <Link to={`/shining-force-1/character/${character.name.toLowerCase()}`}>
                            <div className='mb-4 w-20 lg:w-24'>
                                <Portrait name={character.name} portrait={character.classes[0].image} />
                            </div>
                        </Link>
                    ))}
                </div>
            </Container>
        </div>
    );
}
