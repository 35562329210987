import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import Container from '../components/common/container';
import Portrait from '../components/common/portrait';
import StatsBlock from '../components/character/stats-block';

import { SF1Characters } from '../data/char';
import StartingWeapon from '../components/character/starting-weapon';
import SpellsBlock from '../components/character/spells-block';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';
import Class from '../utils/classes';
import { GrowthCurve } from '../types/enums/character';

import LinearCurve from '../assets/sf1/growth_linear.png';
import EarlyCurve from '../assets/sf1/growth_early.png';
import LateCurve from '../assets/sf1/growth_late.png';
import InOutCurve from '../assets/sf1/growth_inout.png';

export default function CharacterPage(): ReactElement {
    const params = useParams();
    const character = SF1Characters.find((character) => character.name.toLowerCase() === params.id) || SF1Characters[0];

    const [isPromoted, setIsPromoted] = useState(0);
    const classStats = isPromoted ? character.classes[1] : character.classes[0];

    function getGrowthCurveImage(growth: GrowthCurve): string {
        switch (growth) {
            case GrowthCurve.LINEAR:
                return LinearCurve;
            case GrowthCurve.EARLY:
                return EarlyCurve;
            case GrowthCurve.LATE:
                return LateCurve;
            case GrowthCurve.INOUT:
                return InOutCurve;
        }
    }

    function renderGrowthEntry(name: string, growth: GrowthCurve): ReactElement {
        return (
            <div className='mb-1 flex w-full items-center justify-between'>
                <p className='text-dark-font'>{name}</p>
                <img className='w-8' src={getGrowthCurveImage(growth)} alt='growth curve' />
            </div>
        );
    }

    return (
        <div className='mx-auto flex flex-col justify-center gap-8 lg:flex-row'>
            <div className='mx-auto flex w-40 flex-shrink-0 flex-col gap-8 lg:m-0'>
                <div className='px-4'>
                    <Portrait name={character.name} portrait={classStats.image} />
                </div>
                <StatsBlock stats={character.stats} />
                <StartingWeapon name={character.startitem?.name} icon={character.startitem?.icon} />
                <SpellsBlock spells={character.spells} />
            </div>
            <div className='flex flex-col gap-8 lg:w-full xl:w-3/5'>
                <Container title={`${character.name} the ${classStats.name}`}>
                    <p className='mb-4 text-dark-font'>
                        <span className='font-bold text-light-font'>Recruitment: </span>
                        {character.acquisition}
                    </p>

                    <div className='w-full'>
                        <div className='flex w-full translate-y-px transform'>
                            {character.classes.map((characterClass, index) => (
                                <div
                                    onClick={() => setIsPromoted(index)}
                                    className={Class.classNames(
                                        'cursor-pointer rounded-t-2xl border border-sf-blue-highlight border-b-sf-blue-light px-3 py-1 font-title text-white',
                                        index === isPromoted ? 'bg-sf-blue-light' : 'bg-sf-blue-medium',
                                    )}
                                >
                                    {characterClass.name}
                                </div>
                            ))}
                        </div>
                        <div className='rounded-b-2xl rounded-r-2xl border border-sf-blue-highlight bg-sf-blue-light p-4'>
                            <div className='mb-8 flex w-full flex-col lg:flex-row'>
                                <div className='w-full pr-12 lg:w-1/2'>
                                    <h3 className='mb-2 font-title text-lg text-white'>Growth Curves:</h3>
                                    <div className='flex w-full justify-between'>
                                        <div className='mr-8 w-1/2'>
                                            <>{renderGrowthEntry('HP', classStats.classAttributes.growthCurves.hp)}</>
                                            <>{renderGrowthEntry('MP', classStats.classAttributes.growthCurves.mp)}</>
                                            <>
                                                {renderGrowthEntry(
                                                    'Crit',
                                                    classStats.classAttributes.growthCurves.crit,
                                                )}
                                            </>
                                        </div>
                                        <div className='mr-8 w-1/2'>
                                            <>
                                                {renderGrowthEntry(
                                                    'Attack',
                                                    classStats.classAttributes.growthCurves.atk,
                                                )}
                                            </>
                                            <>
                                                {renderGrowthEntry(
                                                    'Defense',
                                                    classStats.classAttributes.growthCurves.def,
                                                )}
                                            </>
                                            <>
                                                {renderGrowthEntry(
                                                    'Speed',
                                                    classStats.classAttributes.growthCurves.spd,
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full lg:w-1/2'>
                                    <h3 className='mb-3 font-title text-lg text-white'>Types & Chances:</h3>
                                    <div className='flex justify-between'>
                                        <div className='w-1/2 text-dark-font'>
                                            <p>AI Target Priority: </p>
                                            <p>Movement Type: </p>
                                            <p>Chance to Evade: </p>
                                            <p>Chance to Double Attack: </p>
                                        </div>
                                        <div className='w-1/2 text-white'>
                                            <p className='font-title'>{classStats.classAttributes.priority}</p>
                                            <p className='font-title'>{classStats.classAttributes.movementType}</p>
                                            <p className='font-title'>{classStats.classAttributes.evasion}</p>
                                            <p className='font-title'>{classStats.classAttributes.dblAttack}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 className='font-title text-lg text-white'>Spell Resistances:</h3>
                            <div className='flex'>
                                <div className='w-1/2'>
                                    <p className='mb-1 text-xs text-light-font'>Multiplier on a received hit</p>
                                    <div className='flex justify-between text-base '>
                                        <div className='w-1/2 text-dark-font'>
                                            <p>Blaze: </p>
                                            <p>Freeze: </p>
                                            <p>Bolt: </p>
                                        </div>
                                        <div className='w-1/2 text-white'>
                                            <p className='font-title'>{classStats.classAttributes.blazeResistance}</p>
                                            <p className='font-title'>{classStats.classAttributes.freezeResistance}</p>
                                            <p className='font-title'>{classStats.classAttributes.boltResistance}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-1/2'>
                                    <p className='mb-1 text-xs text-light-font'>
                                        Chance to get affected by status effect
                                    </p>
                                    <div className='flex justify-between text-base '>
                                        <div className='w-1/2 text-dark-font'>
                                            <p>Muddle: </p>
                                            <p>Slow: </p>
                                            <p>Sleep/Desoul: </p>
                                        </div>
                                        <div className='w-1/2 text-white'>
                                            <p className='font-title'>{classStats.classAttributes.muddleChance}</p>
                                            <p className='font-title'>{classStats.classAttributes.slowChance}</p>
                                            <p className='font-title'>{classStats.classAttributes.SleepDesoulChance}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container disablePadding title={`Guides related to ${character.name}`}>
                    <GuideBlock type={GuideType.CHARACTER} topic={character.name} />
                </Container>
            </div>
        </div>
    );
}
