export enum ItemType {
    RESTORATIVE = 'Restorative Item',
    IMPORTANT = 'Important Item',
    STATBOOST = 'Statboosting Item',
    MISC = 'Miscallaneous Item',
    SWORD = 'Swords',
    AXE = 'Axes',
    STAFF = 'Staves',
    ARROW = 'Arrows',
    POLEARM = 'Spears / Lances',
    RING = 'Rings',
}

export enum Shop {
    GUARDIANA = 'Guardiana',
    ALTERONE = 'Alterone',
    RINDO = 'Rindo',
    BUSTOKE = 'Bustoke',
    PAO = 'Pao',
    URANBATOL = 'Uranbatol',
    WARAL = 'Waral',
    RUDO = 'Rudo',
    DRAGONIA = 'Dragonia',
    PROMPT = 'Prompt',
    RUNEFAUST = 'Runefaust',
}
