import React, { ReactElement, useEffect } from 'react';
import useLocale from '../../hooks/use-locale';
import parse from 'html-react-parser';

import Container from '../../components/common/container';
import useNewsEntries from '../../hooks/use-news-entry';
import { asyncInvoke } from '../../utils/function';
import { convertToTimeString } from '../../utils/timestring';

export default function News(): ReactElement {
    const { getText } = useLocale();

    const { newsEntries, fetchNewestNewsEntries, isLoading } = useNewsEntries();

    useEffect(asyncInvoke(fetchNewestNewsEntries), []);

    return (
        <Container disablePadding title='News'>
            <div className='max-h-[585px] overflow-y-scroll'>
                {isLoading ? (
                    <p>Loading</p>
                ) : (
                    newsEntries.map((newsEntry) => (
                        <div className='pt-4'>
                            <h3 className='px-4 font-title text-white'>{newsEntry.title}</h3>
                            <div className='mb-4 px-4'>
                                <p className='text-xs text-dark-font'>{convertToTimeString(newsEntry.date_created)}</p>
                                {newsEntry.date_updated && (
                                    <p> - Updated: {convertToTimeString(newsEntry.date_updated)}</p>
                                )}
                            </div>
                            <div className='guide-content px-4'>{parse(newsEntry.content)}</div>
                            <div className='mx-auto mt-4 h-[2px] w-full bg-gradient-to-r from-transparent via-sf-blue-highlight to-transparent' />
                        </div>
                    ))
                )}
            </div>
        </Container>
    );
}
