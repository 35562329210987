import React, { ReactElement } from 'react';

import { Item } from '../../types/item';
import { Shop } from '../../types/enums/items';

type Props = {
    shop: Shop;
    items: Item[];
    equipment: Item[];
};

export default function ShopTable(props: Props): ReactElement {
    const { shop, items, equipment } = props;

    return (
        <div>
            <div className='bg-sf-blue-highlight text-center font-title text-xl text-white'>{shop}</div>
            <div className='flex flex-row'>
                <div className=' w-1/5 bg-sf-blue-medium'>
                    <p className='sf-border bg-sf-blue-highlight py-2 text-center font-menu text-[10px] uppercase text-white lg:p-2 lg:text-xs'>{`Item Shop`}</p>
                </div>
                <table className='w-4/5'>
                    <tr className='bg-sf-blue-medium text-white'>
                        <th className='w-8 pl-2 text-left'></th>
                        <th className='w-32'>Name</th>
                        <th className='w-12'>Price</th>
                        <th className='hidden lg:block'>Description</th>
                    </tr>
                    {items.map((item) => (
                        <tr className='border-y border-sf-blue-darkest bg-sf-blue-dark text-center text-dark-font'>
                            <td>
                                <img className='my-1 ml-2 w-6 lg:w-auto' src={item.icon} alt='enemy icon' />
                            </td>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                            <td className='hidden lg:block'>{item.description}</td>
                        </tr>
                    ))}
                </table>
            </div>
            {shop === Shop.DRAGONIA || shop === Shop.URANBATOL ? (
                <></>
            ) : (
                <div className='flex flex-row'>
                    <div className='w-1/5 bg-sf-blue-medium '>
                        <p className='sf-border bg-sf-blue-highlight py-2 text-center font-menu text-[8px] uppercase text-white lg:p-2 lg:text-xs'>{`Weapon Shop`}</p>
                    </div>
                    <table className='w-4/5'>
                        <tr className='bg-sf-blue-medium text-white'>
                            <th className='w-8 pl-2 text-left'></th>
                            <th className='w-32'>Name</th>
                            <th className='w-12'>Price</th>
                            <th className='w-16'>Attribute</th>
                            <th className='w-12'>Range</th>
                            <th className='hidden lg:block'>Equippable By:</th>
                        </tr>
                        {equipment.map((item) => (
                            <tr className='border-y border-sf-blue-darkest bg-sf-blue-dark text-center text-dark-font'>
                                <td>
                                    <img className='my-1 ml-2 w-6 lg:w-auto' src={item.icon} alt='item icon' />
                                </td>
                                <td>{item.name}</td>
                                <td>{item.price}</td>
                                <td>{item.attribute}</td>
                                <td>{item.range}</td>
                                <td className='hidden lg:block'>{item.equippableBy}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            )}
        </div>
    );
}
