import React, { ReactElement } from 'react';
import ThumbnailPlaceholder from '../../assets/thumbnail-placeholder.jpg';
import { Link } from 'react-router-dom';

type Props = {
    id: string;
    title: string;
    thumbnail: string | undefined;
    author: string;
    createdAt: string;
    updatedAt: string;
};

export default function GuideEntry(props: Props): ReactElement {
    const { id, title, thumbnail, author, createdAt, updatedAt } = props;

    const thumbnailPath = thumbnail ? `https://cms.shiningforce-sr.com/assets/${thumbnail}` : undefined;

    return (
        <Link to={`/shining-force-1/guide/${id}`}>
            <div className='flex h-12 w-full cursor-pointer overflow-hidden border-b border-sf-blue-highlight bg-sf-blue-darkest transition-all hover:bg-sf-blue-dark'>
                <img
                    className='hidden h-full w-20 flex-shrink-0 object-cover lg:block'
                    src={thumbnailPath || ThumbnailPlaceholder}
                    alt=''
                />
                <div className='flex-1 -translate-y-1 px-3 py-1'>
                    <h3 className='w-[300px] translate-y-1 truncate font-title text-sm text-blue-200 xl:w-[400px] xl:text-base'>
                        {title}
                    </h3>
                    <p className='font-bold text-yellow-200 lg:text-sm'>
                        <span className='text-xs text-dark-font'>By: </span>
                        {author}
                    </p>
                </div>
                <div className='hidden h-full w-36 flex-shrink-0 flex-col justify-center border-l-2 border-sf-blue-highlight bg-sf-blue-dark px-2 py-1 lg:flex'>
                    <p className='text-xs text-blue-200'>
                        <span className='inline-block w-16 text-xs text-dark-font'>Created:</span>
                        {createdAt}
                    </p>
                    <p className=' text-xs text-blue-200'>
                        <span className='inline-block w-16 text-xs text-dark-font'>Last Updated:</span>
                        {updatedAt}
                    </p>
                </div>
            </div>
        </Link>
    );
}
