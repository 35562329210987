import React, { ReactElement } from 'react';
import { CharacterStats } from '../../types/character';

type Props = {
    stats: CharacterStats;
};

export default function StatsBlock(props: Props): ReactElement {
    const { stats } = props;

    return (
        <div className='sf-border w-full bg-sf-blue-highlight p-2 pb-1'>
            <h3 className='mb-2 text-center font-title text-sm uppercase text-white'>Starting Stats:</h3>
            {Object.entries(stats).map(([key, value]) => {
                return (
                    <div className='mb-1 flex justify-between'>
                        <p className='font-menu text-xs uppercase text-white'>{key}</p>
                        <p className='font-menu text-xs text-white'>{value}</p>
                    </div>
                );
            })}
        </div>
    );
}
