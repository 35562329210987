import React, { ReactElement } from 'react';
import { ItemType } from '../../types/enums/items';
import { SF1Equipment } from '../../data/equipment';

type Props = {
    equipmentType: ItemType;
};

export default function EquipmentTable(props: Props): ReactElement {
    const { equipmentType } = props;

    const equipmentData = SF1Equipment.filter((item) => item.type === equipmentType);

    return (
        <>
            <div className=' w-full bg-sf-blue-medium px-4 py-1 text-center text-xl font-bold text-white'>
                <p>{equipmentType}</p>
            </div>
            <p className='text-center font-bold text-white lg:hidden'>Swipe horizontally</p>
            <div className='overflow-x-scroll lg:overflow-x-auto '>
                <table className='w-[800px] lg:w-full'>
                    <tr className='bg-sf-blue-medium text-white'>
                        <th className='w-8 pl-2 text-left'></th>
                        <th className='w-32'>Name</th>
                        <th className='w-12'>Price</th>
                        <th className='w-16'>Attribute</th>
                        <th className='w-12'>Range</th>
                        <th className='w-24'>Equippable By:</th>
                        <th className='w-64'>Location</th>
                        <th>Special</th>
                    </tr>
                    {equipmentData.map((item) => (
                        <tr className='border-y border-sf-blue-darkest bg-sf-blue-dark text-center text-dark-font'>
                            <td>
                                <img className='my-1 ml-2' src={item.icon} alt='item icon' />
                            </td>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                            <td>{item.attribute}</td>
                            <td>{item.range}</td>

                            <td>{item.equippableBy}</td>
                            <td>{item.location}</td>
                            <td>{item.description}</td>
                        </tr>
                    ))}
                </table>
            </div>
        </>
    );
}
