import React, { ReactElement } from 'react';
import { CharacterSpell } from '../../types/character';
import SpellLevel from '../../assets/sf1/spell_level_active.png';

type Props = {
    spells: CharacterSpell[];
};

export default function SpellsBlock(props: Props): ReactElement {
    const { spells } = props;

    return (
        <div className='sf-border w-full bg-sf-blue-highlight p-2 pb-1'>
            <h3 className='mb-1 text-center font-title text-sm uppercase text-white'>{`Spells: ${
                spells.length === 0 ? 'NONE' : ''
            }`}</h3>
            {spells.map((spell) => {
                return (
                    <div className='mb-1 flex items-end'>
                        <img className='mr-1 w-8' src={spell.icon} alt='Spell Icon' />
                        <div>
                            <p className='font-menu text-xs uppercase text-white'>{spell.name}</p>
                            <div className='flex'>
                                {spell.levels.map((level) => {
                                    return (
                                        <div className='flex flex-col items-center justify-center'>
                                            <img className='w-5' src={SpellLevel} />
                                            <p className='-translate-x-px font-menu text-[8px] text-white'>{level}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
