import React, { ReactElement, useEffect } from 'react';

import Container from '../components/common/container';
import DialogueBanner from '../components/common/dialogue-banner';
import useLocale from '../hooks/use-locale';
import NakuAnimated from '../assets/naku_animated.gif';
import ShiningFriendsDiscord from '../assets/discord.png';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';

export default function BeginnerPage(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='mx-auto flex flex-col items-center gap-8'>
            <div className='mx-auto flex w-full flex-col items-center justify-center gap-8 lg:flex-row'>
                <div className='w-4/5 xl:w-3/5'>
                    <DialogueBanner
                        copy={getText('shining-force-1-page.pages.beginner.dialogue-box')}
                        portrait={NakuAnimated}
                    />
                </div>
                <a className='w-4/5 lg:w-1/5' href='https://discord.gg/RVhZwpyRRc' target='_blank' rel='noreferrer'>
                    <img className='w-full' src={ShiningFriendsDiscord} alt='Shining Friends Discord' />
                </a>
            </div>
            <div className='w-full lg:w-4/5'>
                <Container disablePadding title={`Guides tagged as for Beginners`}>
                    <GuideBlock type={GuideType.GENERAL} topic='Beginner' />
                </Container>
            </div>
        </div>
    );
}
