import React, { ReactElement } from 'react';
import useLocale from '../hooks/use-locale';

import Container from '../components/common/container';
import GameStage from '../components/landing-page/game-stage';
import DialogueBanner from '../components/common/dialogue-banner';

import Galam from '../assets/galam.png';
import NakuAnimated from '../assets/naku_animated.gif';
import ContributeGraphic from '../assets/contribute.jpg';
import ShiningFriendsDiscord from '../assets/discord.png';
import News from '../components/landing-page/news';

export default function LandingPage(): ReactElement {
    const { getText } = useLocale();

    return (
        <>
            <div className='mb-16 flex w-full flex-col gap-8 lg:mb-32 lg:flex-row'>
                <Container title={getText('landing-page.welcome-block.title', <br />)}>
                    <p className='text-justify text-lg text-dark-font'>{getText('landing-page.welcome-block.copy')}</p>
                    <img className='mb-8 w-full' src={Galam} alt='King Galam Battlesprite' />
                    <p className='text-justify text-lg text-dark-font'>{getText('landing-page.welcome-block.copy2')}</p>
                </Container>
                <GameStage />
            </div>
            <div className='mb-16'>
                <DialogueBanner portrait={NakuAnimated} copy={getText('landing-page.dialogue-box')} />
            </div>

            <div className='mb-8 flex w-full flex-col gap-8 lg:flex-row'>
                <div className='h-full lg:w-1/3'>
                    <News />
                </div>
                <div className='flex w-full flex-col gap-8 lg:w-2/3'>
                    <Container title='Contribute to Shining Force SR'>
                        <div>
                            <div className='mb-8 flex flex-col items-center gap-5 lg:flex-row '>
                                <img
                                    className='sf-border w-full lg:w-2/5'
                                    src={ContributeGraphic}
                                    alt='Shining Force 2 party'
                                />

                                <p className='text-dark-font lg:w-3/5'>
                                    This site wouldn't be possible for the many generous helping hands creating guides
                                    and working on all the information displayed on Shining Force SR. If you also want
                                    to help and create guides, we would love to have you.{' '}
                                    <span className='text-red-300'>
                                        Sadly as of right now, I'm still working on the process to admit new people to
                                        the system.
                                    </span>{' '}
                                    I will keep you updated on the status of that process. But until then, you are
                                    welcome to enter a waiting list to express your interest to join the editorial of
                                    this site. Depending on the circumstances, I might find an arrangement to get you on
                                    board ahead of time.
                                </p>
                            </div>
                            <div className='flex justify-center'>
                                <a
                                    className='text-center text-2xl font-bold text-light-font underline'
                                    href='https://forms.gle/ivkuHgwX3vw4jMTR8'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Waiting List Signup
                                </a>
                            </div>
                        </div>
                    </Container>
                    <div className='flex flex-col items-center gap-8 lg:flex-row'>
                        <div className='lg:w-3/5'>
                            <Container title='Feedback & Questions'>
                                <p className='mb-8 text-dark-font'>
                                    If you have any feedback or questions, please contact me either directly via Discord
                                    or send an email. When writing on Discord, best you mention Shining Force SR in your
                                    opening message. Thanks!
                                </p>
                                <p className='text-white'>
                                    <span className='inline-block w-16 font-bold text-dark-font'>Discord:</span>{' '}
                                    Nakuri.nakuri
                                </p>
                                <p className='text-white'>
                                    <span className='inline-block w-16 font-bold text-dark-font'>Email:</span>{' '}
                                    admin@shiningforce-sr.com
                                </p>
                            </Container>
                        </div>
                        <a
                            className='w-full lg:w-2/5'
                            href='https://discord.gg/RVhZwpyRRc'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img className='w-full' src={ShiningFriendsDiscord} alt='Shining Friends Discord' />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
