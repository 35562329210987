import React, { ReactElement } from 'react';
import Portrait from './portrait';

type Props = {
    portrait: string;
    copy: string;
};

export default function DialogueBanner(props: Props): ReactElement {
    const { portrait, copy } = props;

    return (
        <div className='flex w-full flex-col items-center gap-8 lg:flex-row'>
            <div className='w-24 lg:w-32'>
                <Portrait name='Nakuri' portrait={portrait} />
            </div>
            <div className='sf-border w-full bg-sf-blue-highlight p-2 lg:p-4'>
                <p className='font-dialogue text-lg text-white lg:text-2xl'>{copy}</p>
            </div>
        </div>
    );
}
