import { useState } from 'react';
import { ServiceError } from '../common/error';
import { Guide } from '../types/collections';

const API_BASEURL = 'https://cms.shiningforce-sr.com/items';
const CONTENT_ENDPOINT = `${API_BASEURL}/sf1guides`;
const FIELDS = '/?fields=*,user_created.first_name,user_created.last_name,thumbnail.id';

type GuidesApi = {
    guide: Guide | undefined;
    guides: Guide[];
    fetchNewestGuides: () => Promise<void>;
    fetchGuideById: (id: string) => Promise<void>;
    fetchGuides: (topic: string) => Promise<void>;
    isLoading: boolean;
};

export enum GuidesErrorType {
    UNKNOWN = 'UNKNOWN',
    NETWORK_ERROR = 'NETWORK_ERROR',
    UNAUTHORIZED = 'UNAUTHORIZED',
    EMPTY_GUIDES = 'EMPTY_GUIDES',
}

export class GuidesError extends ServiceError<GuidesErrorType> {}

function sortByLastUpdated(array: Guide[]): Guide[] {
    return array
        .sort(function (x, y) {
            const firstDate = Date.parse(x.date_updated !== undefined ? x.date_updated : x.date_created);
            const secondDate = Date.parse(y.date_updated !== undefined ? y.date_updated : y.date_created);
            return firstDate - secondDate;
        })
        .reverse()
        .slice(0, 5);
}

export default function useGuides(): GuidesApi {
    const [guide, setGuide] = useState<Guide>();
    const [guides, setGuides] = useState<Guide[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchNewestGuides(): Promise<void> {
        setIsLoading(true);

        try {
            const res = await fetch(`${CONTENT_ENDPOINT}${FIELDS}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                throw new GuidesError(GuidesErrorType.NETWORK_ERROR, 'Failed to fetch interest categories');
            }

            const jsonData = await res.json();

            const newestGuides = sortByLastUpdated(jsonData.data);

            setGuides(newestGuides);
        } catch (error: Error | any) {
            console.error(error.message);
            throw new GuidesError(GuidesErrorType.NETWORK_ERROR, error.message);
        }

        setIsLoading(false);
    }

    async function fetchGuideById(id: string): Promise<void> {
        setIsLoading(true);

        try {
            const res = await fetch(`${CONTENT_ENDPOINT}/${id}${FIELDS}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                throw new GuidesError(GuidesErrorType.NETWORK_ERROR, 'Failed to fetch interest categories');
            }

            const jsonData = await res.json();

            setGuide(jsonData.data);
        } catch (error: Error | any) {
            console.error(error.message);
            throw new GuidesError(GuidesErrorType.NETWORK_ERROR, error.message);
        }

        setIsLoading(false);
    }

    async function fetchGuides(): Promise<void> {
        setIsLoading(true);

        try {
            const res = await fetch(`${CONTENT_ENDPOINT}${FIELDS}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                throw new GuidesError(GuidesErrorType.NETWORK_ERROR, 'Failed to fetch interest categories');
            }

            const jsonData = await res.json();

            setGuides(jsonData.data);
        } catch (error: Error | any) {
            console.error(error.message);
            throw new GuidesError(GuidesErrorType.NETWORK_ERROR, error.message);
        }

        setIsLoading(false);
    }

    return {
        guide,
        guides,
        fetchNewestGuides,
        fetchGuideById,
        fetchGuides,
        isLoading,
    };
}
