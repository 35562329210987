import React, { ReactElement } from 'react';
import DiscordLogo from '../../../assets/discord-mark-blue.png';
import { Link } from 'react-router-dom';

type Props = {
    expanded: boolean;
    onClick: () => void;
};

export default function RightHeaderNav(props: Props): ReactElement {
    const { expanded, onClick } = props;

    return (
        <>
            <div
                onClick={onClick}
                className='hover:border-bright-text relative rounded-full border-[1px] border-gray-700 px-4 py-2 duration-300 lg:hidden'
            >
                <div className='absolute left-0 top-0 h-full w-full rounded-full bg-black opacity-20 duration-300 group-hover:bg-sf-blue-medium' />
                <p className='text-white'>Menu</p>
            </div>

            {expanded && (
                <div className='absolute -bottom-2 right-0 rounded-full border-[1px] border-gray-700 px-4 py-2'>
                    <div className='absolute left-0 top-0 h-full w-full rounded-full bg-black opacity-20 duration-300 group-hover:bg-sf-blue-medium' />
                    <div className='flex items-center space-x-6'>
                        <Link to='/shining-friends'>
                            <p className='text-md cursor-pointer text-light-font'>The Shining Friends</p>
                        </Link>
                        <a
                            className='flex cursor-pointer items-center justify-center'
                            href='https://discord.gg/RVhZwpyRRc'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img className='w-5' src={DiscordLogo} alt='Discord Logo' />
                        </a>
                    </div>
                </div>
            )}

            <div className='hidden lg:block'>
                <div className='flex items-center justify-start'>
                    <Link to='/shining-friends'>
                        <p className='text-md mx-1 cursor-pointer rounded-full px-4 py-2 text-light-font transition-colors hover:bg-[#00000070]'>
                            The Shining Friends
                        </p>
                    </Link>
                    <a
                        className='ml-5 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full transition-colors hover:bg-[#5865F240]'
                        href='https://discord.gg/RVhZwpyRRc'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className='w-5' src={DiscordLogo} alt='Discord Logo' />
                    </a>
                </div>
            </div>
        </>
    );
}
