import React, { MutableRefObject, ReactElement, useEffect, useRef } from 'react';

import { Guide } from '../types/collections';
import useGuides from '../hooks/use-guides';
import { convertToTimeString } from '../utils/timestring';
import { asyncInvoke } from '../utils/function';
import { ItemType } from '../types/enums/items';

import ItemTable from '../components/items/item-table';
import EquipmentTable from '../components/items/equipment-table';
import Container from '../components/common/container';
import GuideEntry from '../components/common/guide-entry';

import { SF1Items } from '../data/items';

import ItemArt from '../assets/sf1/items_art.png';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';

export default function ItemPage(): ReactElement {
    const { guides, fetchGuides, isLoading } = useGuides();

    const itemsRef = useRef() as MutableRefObject<HTMLDivElement>;
    const swordRef = useRef() as MutableRefObject<HTMLDivElement>;
    const polearmRef = useRef() as MutableRefObject<HTMLDivElement>;
    const axeRef = useRef() as MutableRefObject<HTMLDivElement>;
    const staffRef = useRef() as MutableRefObject<HTMLDivElement>;
    const arrowRef = useRef() as MutableRefObject<HTMLDivElement>;
    const ringRef = useRef() as MutableRefObject<HTMLDivElement>;

    function filterGuidesByEnemy(): Guide[] {
        return guides.filter((guide) => guide.topics.includes('Items'));
    }

    function scrollTo(ref: React.MutableRefObject<HTMLDivElement>): void {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(asyncInvoke(fetchGuides), []);

    return (
        <div>
            <div className='mb-8 flex flex-col gap-8 lg:flex-row'>
                <div className='w-full lg:w-2/5'>
                    <Container title='Items in Shining Force 1'>
                        <p className='mb-4 text-justify text-dark-font'>
                            You can see a complete list of all items in Shining Force 1 down below or head into
                            community written guides to see more particular info and related topics to enemy behaviour
                            etc. For easier browsing, you can click the following categories to jump directly to the
                            respective spot in the list.
                        </p>
                        <img
                            className='sf-border mx-auto w-2/3'
                            src={ItemArt}
                            alt="Jogurt throwing Max's item into the sea."
                        />
                        <div className='mt-4'>
                            <ul className='mx-2 my-4 flex flex-wrap justify-center gap-2 font-bold text-light-font'>
                                <li
                                    onClick={() => scrollTo(itemsRef)}
                                    className='sf-border w-28 cursor-pointer bg-sf-blue-medium text-center text-white hover:bg-sf-blue-highlight'
                                >
                                    Items
                                </li>
                                <li
                                    onClick={() => scrollTo(swordRef)}
                                    className='sf-border w-28 cursor-pointer bg-sf-blue-medium text-center text-white hover:bg-sf-blue-highlight'
                                >
                                    Swords
                                </li>
                                <li
                                    onClick={() => scrollTo(polearmRef)}
                                    className='sf-border w-28 cursor-pointer bg-sf-blue-medium text-center text-white hover:bg-sf-blue-highlight'
                                >
                                    Spears / Lances
                                </li>
                                <li
                                    onClick={() => scrollTo(axeRef)}
                                    className='sf-border w-28 cursor-pointer bg-sf-blue-medium text-center text-white hover:bg-sf-blue-highlight'
                                >
                                    Axes
                                </li>
                                <li
                                    onClick={() => scrollTo(staffRef)}
                                    className='sf-border w-28 cursor-pointer bg-sf-blue-medium text-center text-white hover:bg-sf-blue-highlight'
                                >
                                    Staves
                                </li>
                                <li
                                    onClick={() => scrollTo(arrowRef)}
                                    className='sf-border w-28 cursor-pointer bg-sf-blue-medium text-center text-white hover:bg-sf-blue-highlight'
                                >
                                    Arrows
                                </li>
                                <li
                                    onClick={() => scrollTo(ringRef)}
                                    className='sf-border w-28 cursor-pointer bg-sf-blue-medium text-center text-white hover:bg-sf-blue-highlight'
                                >
                                    Rings
                                </li>
                            </ul>
                        </div>
                    </Container>
                </div>
                <div className='w-full lg:w-3/5'>
                    <Container disablePadding title='Guides related to Items'>
                        <GuideBlock type={GuideType.GENERAL} topic='Items' />
                    </Container>
                </div>
            </div>
            <div className='mx-auto mb-8 w-full xl:w-4/5' ref={itemsRef}>
                <Container disablePadding title='Items'>
                    <p className='text-center font-bold text-white lg:hidden'>Swipe horizontally</p>
                    <div className='overflow-x-scroll lg:overflow-x-auto '>
                        <ItemTable items={SF1Items} />
                    </div>
                </Container>
            </div>
            <div className='mx-auto mb-8 w-full xl:w-4/5'>
                <Container disablePadding title='Equipment'>
                    <div ref={swordRef}>
                        <EquipmentTable equipmentType={ItemType.SWORD} />
                    </div>
                    <div ref={polearmRef}>
                        <EquipmentTable equipmentType={ItemType.POLEARM} />
                    </div>
                    <div ref={axeRef}>
                        <EquipmentTable equipmentType={ItemType.AXE} />
                    </div>
                    <div ref={staffRef}>
                        <EquipmentTable equipmentType={ItemType.STAFF} />
                    </div>
                    <div ref={arrowRef}>
                        <EquipmentTable equipmentType={ItemType.ARROW} />
                    </div>
                    <div ref={ringRef}>
                        <EquipmentTable equipmentType={ItemType.RING} />
                    </div>
                </Container>
            </div>
        </div>
    );
}
