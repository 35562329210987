import React, { ReactElement } from 'react';

import { ChapterEnemies } from '../../types/enemies';

type Props = {
    enemyArray: ChapterEnemies[];
};

export default function EnemyTable(props: Props): ReactElement {
    const { enemyArray } = props;

    return (
        <>
            {enemyArray.map((chapter) => (
                <>
                    <div className=' w-full bg-sf-blue-medium px-4 font-bold text-white'>
                        <p className='lg:translate-y-4'>{chapter.title}</p>
                    </div>
                    <div className='overflow-x-scroll lg:overflow-x-auto '>
                        <table className='w-[800px] lg:w-full'>
                            <tr className='bg-sf-blue-medium text-white'>
                                <th className='w-16 pl-2 text-left'></th>
                                <th className='w-32'>Name</th>
                                <th className='w-12'>HP</th>
                                <th className='w-12'>MP</th>
                                <th className='w-12'>Atk</th>
                                <th className='w-12'>Def</th>
                                <th className='w-12'>Spd</th>
                                <th className='w-12'>Mov</th>
                                <th className='w-12'>Weapon</th>
                                <th className='w-32'>Spells</th>
                                <th>Description</th>
                            </tr>
                            {chapter.enemies.map((enemy) => (
                                <tr className='border-y border-sf-blue-darkest bg-sf-blue-dark text-center text-dark-font'>
                                    <td>
                                        <img className='mx-auto my-1' src={enemy.img} alt='enemy icon' />
                                    </td>
                                    <td className='text-left'>{enemy.name}</td>
                                    <td>{enemy.hp}</td>
                                    <td>{enemy.mp === '0' ? '' : enemy.mp}</td>
                                    <td>{enemy.atk}</td>
                                    <td>{enemy.def}</td>
                                    <td>{enemy.spd}</td>
                                    <td>{enemy.mov}</td>
                                    <td>
                                        {enemy.weapon !== '' && (
                                            <img className='mx-auto w-5' src={enemy.weapon} alt='weapon icon' />
                                        )}
                                    </td>
                                    <td>{enemy.spells}</td>
                                    <td>{enemy.description}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </>
            ))}
        </>
    );
}
