import { useState } from 'react';
import { ServiceError } from '../common/error';
import { Guide, NewsEntry } from '../types/collections';

const API_BASEURL = 'https://cms.shiningforce-sr.com/items';
const CONTENT_ENDPOINT = `${API_BASEURL}/news_entry`;

type NewsEntryApi = {
    newsEntries: NewsEntry[];
    fetchNewestNewsEntries: () => Promise<void>;
    isLoading: boolean;
};

export enum NewsEntryErrorType {
    UNKNOWN = 'UNKNOWN',
    NETWORK_ERROR = 'NETWORK_ERROR',
    UNAUTHORIZED = 'UNAUTHORIZED',
    EMPTY_NEWS_ENTRIES = 'EMPTY_NEWS_ENTRIES',
}

export class NewsEntryError extends ServiceError<NewsEntryErrorType> {}

function sortByLastUpdated(array: Guide[]): Guide[] {
    return array
        .sort(function (x, y) {
            const firstDate = Date.parse(x.date_updated !== undefined ? x.date_updated : x.date_created);
            const secondDate = Date.parse(y.date_updated !== undefined ? y.date_updated : y.date_created);
            return firstDate - secondDate;
        })
        .reverse();
}

export default function useNewsEntries(): NewsEntryApi {
    const [newsEntries, setNewsEntries] = useState<NewsEntry[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchNewestNewsEntries(): Promise<void> {
        setIsLoading(true);

        try {
            const res = await fetch(CONTENT_ENDPOINT, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                throw new NewsEntryError(NewsEntryErrorType.NETWORK_ERROR, 'Failed to fetch interest categories');
            }

            const jsonData = await res.json();

            const newestNewsEntries = sortByLastUpdated(jsonData.data);

            setNewsEntries(newestNewsEntries);
        } catch (error: Error | any) {
            console.error(error.message);
            throw new NewsEntryError(NewsEntryErrorType.NETWORK_ERROR, error.message);
        }

        setIsLoading(false);
    }

    return {
        newsEntries,
        fetchNewestNewsEntries,
        isLoading,
    };
}
