import React, { ReactElement } from 'react';

type Props = {
    portrait: string;
    name: string;
};

export default function Portrait(props: Props): ReactElement {
    const { portrait, name } = props;

    return (
        <div className='relative'>
            <img className='sf-border w-full' src={portrait} alt='Dialogue character portrait' />
            <div className='sf-border absolute -bottom-4 left-1/2 -translate-x-1/2 bg-sf-blue-highlight px-1 py-[1px]'>
                <p className='whitespace-nowrap font-menu text-xs text-white'>{name}</p>
            </div>
        </div>
    );
}
