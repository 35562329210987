import React, { ReactElement, useEffect } from 'react';

import useLocale from '../../hooks/use-locale';
import useLeaderboard from '../../hooks/use-leaderboard';
import { asyncInvoke } from '../../utils/function';

import Container from '../../components/common/container';
import moment from 'moment';
import { LeaderboardPlayer } from '../../types/leaderboard';

import { ReactComponent as SpeedrunDotComLogo } from '../../assets/speedrun-com.svg';

export default function Leaderboard(): ReactElement {
    const { getText } = useLocale();
    const { leaderboard, fetchLeaderboard, isLoading } = useLeaderboard();

    useEffect(asyncInvoke(fetchLeaderboard), []);

    function nth(place: number) {
        return ['st', 'nd', 'rd'][((((place + 90) % 100) - 10) % 10) - 1] || 'th';
    }

    function filterById(jsonObject: LeaderboardPlayer[], id: string): LeaderboardPlayer {
        return jsonObject.filter(function (jsonObject) {
            return jsonObject['id'] === id;
        })[0];
    }

    function getRunTimeFormatted(runtime: number): string {
        return moment.utc(runtime * 1000).format('H:mm:ss');
    }

    function openLink(link: string): void {
        window.open(link, '_blank');
    }

    return (
        <Container disablePadding title='Leaderboard'>
            <div className='flex  items-center justify-end space-x-4 bg-[#2b49ff20] px-2 pb-1'>
                <p className='-translate-y-1 transform text-xs font-bold text-white'>Data from:</p>
                <div
                    onClick={() => {
                        openLink('https://www.speedrun.com/shining_force_1');
                    }}
                    className='w-24 -translate-y-1 transform cursor-pointer'
                >
                    <SpeedrunDotComLogo />
                </div>
            </div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <table className='w-full'>
                    <tr className='bg-sf-blue-dark px-4 text-xl font-bold text-white'>
                        <th className='w-16'>Place</th>
                        <th className='pl-8 text-left'>Player</th>
                        <th className='w-24'>Time</th>
                    </tr>
                    {leaderboard?.runs.map((run) => (
                        <tr
                            onClick={() => {
                                openLink(run.run.weblink);
                            }}
                            className='cursor-pointer px-4 text-lg text-dark-font hover:bg-sf-blue-medium'
                        >
                            <td>
                                <p className='text-center'>{`${run.place}${nth(run.place)}`}</p>
                            </td>
                            <td className='pl-8'>
                                <p className='font-bold drop-shadow filter'>
                                    {filterById(leaderboard.players.data, run.run.players[0].id).names.international}
                                </p>
                            </td>
                            <td className='text-center font-bold text-white'>
                                {getRunTimeFormatted(run.run.times.primary_t)}
                            </td>
                        </tr>
                    ))}
                </table>
            )}
        </Container>
    );
}
