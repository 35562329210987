import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SF1Chapters } from '../data/chapter';
import Container from '../components/common/container';
import EnemyTable from '../components/enemy/enemy-table';
import { SF1Enemies } from '../data/enemies';
import { SF1Items } from '../data/items';
import ShopTable from '../components/items/shop-table';
import { SF1Equipment } from '../data/equipment';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';

export default function ChapterPage(): ReactElement {
    const params = useParams();
    const chapter = SF1Chapters.find((chapters) => chapters.id === params.id) || SF1Chapters[0];

    return (
        <div className='mx-auto flex flex-col gap-8 lg:flex-row'>
            <div className='flex w-full flex-col gap-8 lg:w-1/5'>
                <Container disablePadding title={`${chapter.name}`}>
                    <></>
                </Container>
                <Container title={`Battles`}>
                    <div className='flex flex-col items-center gap-4'>
                        {chapter.battles.map((battle) => (
                            <div className='relative w-3/5 lg:w-full'>
                                <img className='sf-border w-full' src={battle.img} alt='Battle Thumbnail' />
                                <p className='sf-border absolute bottom-0 right-0 whitespace-nowrap bg-sf-blue-highlight px-1 py-[1px] text-center font-menu text-xs text-white'>
                                    {battle.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
            <div className='flex w-full flex-col gap-8 lg:w-4/5'>
                <Container disablePadding title={`Guides related to ${chapter.name}`}>
                    <GuideBlock type={GuideType.CHAPTER} topic={chapter.id} />
                </Container>
                <Container disablePadding title={`Enemies`}>
                    <EnemyTable enemyArray={SF1Enemies.filter((c) => c.id === chapter.id)} />
                </Container>
                <Container disablePadding title={`Shops`}>
                    {chapter.shops.map((shop) => (
                        <ShopTable
                            shop={shop}
                            items={SF1Items.filter((item) => item.shop?.includes(shop))}
                            equipment={SF1Equipment.filter((item) => item.shop?.includes(shop))}
                        />
                    ))}
                </Container>
            </div>
        </div>
    );
}
