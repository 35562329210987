import LocalizedStrings from 'react-localization';
import en from './en.json';

const TextContainer = new LocalizedStrings({
  en: {
    ...en
  }
});

export default TextContainer;
