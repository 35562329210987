import React, { ReactElement, useEffect, useState } from 'react';
import CondensedLogo from '../../../assets/logo_condensed.svg';
import GameSelection from './game-selection';
import RightHeaderNav from './right-header-nav';
import SelectGameButton from './select-game-button';
import { Link, useLocation } from 'react-router-dom';

export default function Header(): ReactElement {
    const [expandedGameSelection, setExpandedGameSelection] = useState(false);
    const [expandedMenu, setExpandedMenu] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setExpandedGameSelection(false);
        window.scrollTo({ top: 0, left: 0 });
    }, [location]);

    function toggleGameSelection(): void {
        setExpandedMenu(false);
        setExpandedGameSelection(!expandedGameSelection);
    }

    function toggleMenu(): void {
        setExpandedGameSelection(false);
        setExpandedMenu(!expandedMenu);
    }

    return (
        <div className='relative mb-6 pt-8 lg:mb-12 lg:pt-20'>
            <div className='mb-4 flex w-full items-start justify-between'>
                <Link to='/'>
                    <img className='w-24 lg:w-28' src={CondensedLogo} alt='Shining Force SR Logo' />
                </Link>
                <SelectGameButton expanded={expandedGameSelection} onClick={toggleGameSelection} />
                <RightHeaderNav expanded={expandedMenu} onClick={toggleMenu} />
            </div>
            <GameSelection expanded={expandedGameSelection} />
        </div>
    );
}
