import React, { ReactElement } from 'react';

import Container from '../components/common/container';
import useLocale from '../hooks/use-locale';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';
import MechanicsArt from '../assets/sf1/mechanics_art.png';

export default function MechanicsPage(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='mx-auto flex flex-col items-center gap-8'>
            <div className='mx-auto flex w-full items-center justify-center gap-8 lg:w-4/5'>
                <Container disablePadding title={'Mechanics in Shining Force 1'}>
                    <div className='flex flex-col items-center lg:flex-row lg:space-x-5'>
                        <img className='w-80' src={MechanicsArt} alt='Max counting Dark Dwarves' />
                        <p className='p-4 text-base text-dark-font xl:text-lg'>
                            If you want to gain a deeper understanding of how to play battles efficiently, you'll want
                            to know how the underlying mechanics of the game work. This includes topics like AI
                            Targeting, Levelups and targeted Statgrowths, Promotion Sickness and more. Check out these
                            guides here to get an overview of what to consider when creating your optimal strategy.
                        </p>
                    </div>
                </Container>
            </div>
            <div className='w-full lg:w-4/5'>
                <Container disablePadding title={`Guides related to Mechanics`}>
                    <GuideBlock type={GuideType.GENERAL} topic='Mechanics' />
                </Container>
            </div>
        </div>
    );
}
