export enum GrowthCurve {
    LINEAR = 'Linear',
    EARLY = 'early',
    LATE = 'late',
    INOUT = 'in-and-out',
}

export enum Movement {
    STANDARD = 'Standard',
    MOUNTED = 'Mounted',
    FOREST = 'Forest',
    FLYING = 'Flying',
    MECHANICAL = 'Mechanical',
    HOVERING = 'Hovering',
    AQUATIC = 'Aquatic',
}

export enum DoubleAttackChance {
    STANDARD = '7%',
    SPECIAL = '7% + ((level * 2) / 3)',
}

export enum EvasionChance {
    BASE = '3%',
    TWENTYFIVE = '25%',
    FIFTY = '50%',
    WEAKNESS = '3%',
}

export enum StatusEffectChance {
    BASE = '37%',
    TWENTYFIVE = '18%',
    FIFTY = '0%',
    WEAKNESS = '46%',
}

export enum ElementalResistance {
    BASE = '1x',
    TWENTYFIVE = '0.75x',
    FIFTY = '0.50x',
    WEAKNESS = '1.25x',
}

export enum TargetPriority {
    BASE = '0',
    TWENTYFIVE = '25',
    FIFTY = '50',
    SEVENTYFIVE = '75',
    HUNDRED = '100',
}
