import React, { ReactElement } from 'react';

import { Item } from '../../types/item';

type Props = {
    items: Item[];
};

export default function ItemTable(props: Props): ReactElement {
    const { items } = props;

    return (
        <table className='w-[800px] lg:w-full'>
            <tr className='bg-sf-blue-medium text-white'>
                <th className='w-8 pl-2 text-left'></th>
                <th className='w-32'>Name</th>
                <th className='w-12'>Price</th>
                <th className='w-84'>Description</th>
                <th>Location</th>
            </tr>
            {items.map((item) => (
                <tr className='border-y border-sf-blue-darkest bg-sf-blue-dark text-center text-dark-font'>
                    <td>
                        <img className='my-1 ml-2' src={item.icon} alt='enemy icon' />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                    <td>{item.description}</td>
                    <td>{item.location}</td>
                </tr>
            ))}
        </table>
    );
}
