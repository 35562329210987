import { ItemType, Shop } from '../types/enums/items';
import { Item } from '../types/item';

import ShortSwordIcon from '../assets/sf1/item/short_sword.png';
import MiddleSwordIcon from '../assets/sf1/item/middle_sword.png';
import LongSwordIcon from '../assets/sf1/item/long_sword.png';
import SteelSwordIcon from '../assets/sf1/item/steel_sword.png';
import BroadSwordIcon from '../assets/sf1/item/broad_sword.png';
import DoomBladeIcon from '../assets/sf1/item/doom_blade.png';
import KatanaIcon from '../assets/sf1/item/katana.png';
import SwordOfLightIcon from '../assets/sf1/item/sword_of_light.png';
import SwordOfDarknessIcon from '../assets/sf1/item/sword_of_darkness.png';
import ChaosBreakerIcon from '../assets/sf1/item/chaos_breaker.png';

import SpearIcon from '../assets/sf1/item/spear.png';
import BronzeLanceIcon from '../assets/sf1/item/bronze_lance.png';
import PowerSpearIcon from '../assets/sf1/item/power_spear.png';
import SteelLanceIcon from '../assets/sf1/item/steel_lance.png';
import ChromeLanceIcon from '../assets/sf1/item/chrome_lance.png';
import HalberdIcon from '../assets/sf1/item/halberd.png';
import DevilLanceIcon from '../assets/sf1/item/devil_lance.png';
import ValkyrieIcon from '../assets/sf1/item/valkyrie.png';

import HandAxeIcon from '../assets/sf1/item/hand_axe.png';
import MiddleAxeIcon from '../assets/sf1/item/middle_axe.png';
import BattleAxeIcon from '../assets/sf1/item/battle_axe.png';
import HeatAxeIcon from '../assets/sf1/item/heat_axe.png';
import GreatAxeIcon from '../assets/sf1/item/great_axe.png';
import AtlasIcon from '../assets/sf1/item/atlas.png';

import WoodenStaffIcon from '../assets/sf1/item/wooden_staff.png';
import PowerStaffIcon from '../assets/sf1/item/power_staff.png';
import GuardianStaffIcon from '../assets/sf1/item/guardian_staff.png';
import HolyStaffIcon from '../assets/sf1/item/holy_staff.png';
import DemonRodIcon from '../assets/sf1/item/demon_rod.png';

import WoodenArrowIcon from '../assets/sf1/item/wooden_arrow.png';
import SteelArrowIcon from '../assets/sf1/item/steel_arrow.png';
import ElvenArrowIcon from '../assets/sf1/item/elven_arrow.png';
import AssaultShellIcon from '../assets/sf1/item/assault_shell.png';
import BusterShotIcon from '../assets/sf1/item/buster_shot.png';

import SpeedRingIcon from '../assets/sf1/item/speed_ring.png';
import PowerRingIcon from '../assets/sf1/item/power_ring.png';
import ShieldRingIcon from '../assets/sf1/item/shield_ring.png';
import MobilityRingIcon from '../assets/sf1/item/mobility_ring.png';
import WhiteRingIcon from '../assets/sf1/item/white_ring.png';
import BlackRingIcon from '../assets/sf1/item/black_ring.png';
import EvilRingIcon from '../assets/sf1/item/evil_ring.png';
import YogurtRingIcon from '../assets/sf1/item/yogurt_ring.png';

export const SF1Equipment: Item[] = [
    /* Swords */
    {
        type: ItemType.SWORD,
        name: 'Short Sword',
        icon: ShortSwordIcon,
        price: '100',
        attribute: '+5 Atk',
        range: '1',
        equippableBy: 'SDMN HERO \n WARR GLDR \n BDMN SKYW \n SMR NINJ',
        location: 'Shops: Guardiana, Alterone \n Various Chests',
        shop: [Shop.GUARDIANA, Shop.ALTERONE],
    },
    {
        type: ItemType.SWORD,
        name: 'Middle Sword',
        icon: MiddleSwordIcon,
        price: '250',
        attribute: '+8 Atk',
        range: '1',
        equippableBy: 'SDMN HERO \n WARR GLDR \n BDMN SKYW \n SMR NINJ',
        location: 'Shops: Rindo, Bustoke \n Various Chests',
        shop: [Shop.RINDO, Shop.BUSTOKE],
    },
    {
        type: ItemType.SWORD,
        name: 'Long Sword',
        icon: LongSwordIcon,
        price: '750',
        attribute: '+12 Atk',
        range: '1',
        equippableBy: 'SDMN HERO \n BDMN SKYW',
        location: 'Shops: Pao \n Various Chests',
        shop: [Shop.PAO],
    },
    {
        type: ItemType.SWORD,
        name: 'Steel Sword',
        icon: SteelSwordIcon,
        price: '2500',
        attribute: '+16 Atk',
        range: '1',
        equippableBy: 'HERO SKYW',
        location: 'Shops: Waral \n Pao Chest',
        shop: [Shop.WARAL],
    },
    {
        type: ItemType.SWORD,
        name: 'Broad Sword',
        icon: BroadSwordIcon,
        price: '4800',
        attribute: '+20 Atk',
        range: '1',
        equippableBy: 'HERO SKYW',
        location: 'Shops: Prompt, Runefaust \n Dragonia Chest',
        shop: [Shop.PROMPT, Shop.RUNEFAUST],
    },
    {
        type: ItemType.SWORD,
        name: 'Doom Blade',
        icon: DoomBladeIcon,
        price: '7200',
        attribute: '+25 Atk',
        range: '1',
        equippableBy: 'HERO SKYW \n SMR NINJ',
        location: "Hanzou's Starting Weapon, Durahan Drop in 7-1",
        description: 'Chance to instantly kill target',
    },
    {
        type: ItemType.SWORD,
        name: 'Katana',
        icon: KatanaIcon,
        price: '12000',
        attribute: '+30 Atk',
        range: '1',
        equippableBy: 'HERO SKYW \n SMR NINJ',
        location: "Musashi's Starting Weapon",
        description: 'Sets wielders Crit Chance to 13%',
    },
    {
        type: ItemType.SWORD,
        name: 'Sword of Light',
        icon: SwordOfLightIcon,
        price: '32000',
        attribute: '+36 Atk',
        range: '1',
        equippableBy: 'HERO',
        location: 'Chest in Demon Castle',
        description: 'Casts Bolt 2 when used, Cannot Break, Unsellable',
    },
    {
        type: ItemType.SWORD,
        name: 'Sword of Darkness',
        icon: SwordOfDarknessIcon,
        price: '24000',
        attribute: '+40 Atk',
        range: '1',
        equippableBy: 'HERO SKYW',
        location: 'Received in Prompt from the King',
        description: 'Casts Desoul 1 when used, Cannot Break, Cursed: Chance to stun you, Unsellable',
    },
    {
        type: ItemType.SWORD,
        name: 'Chaos Breaker',
        icon: ChaosBreakerIcon,
        price: '18000',
        attribute: '+40 Atk',
        range: '1',
        equippableBy: 'HERO SKYW',
        location: 'Created in Metapha',
        description: 'Casts Freeze 3 when used, Cannot Break, Unsellable',
    },
    /* Spears / Lances */
    {
        type: ItemType.POLEARM,
        name: 'Spear',
        icon: SpearIcon,
        price: '150',
        attribute: '+6 Atk',
        range: '1-2',
        equippableBy: 'KNT PLDN \n WKNT SKYL \n SKNT SBRN',
        location: 'Shops: Guardiana, Alterone, Rindo \n Various Chests',
        shop: [Shop.GUARDIANA, Shop.ALTERONE, Shop.RINDO],
    },
    {
        type: ItemType.POLEARM,
        name: 'Bronze Lance',
        icon: BronzeLanceIcon,
        price: '300',
        attribute: '+9 Atk',
        range: '1',
        equippableBy: 'KNT PLDN \n WKNT SKYL \n SKNT SBRN',
        location: 'Shops: Rindo, Bustoke, Pao \n Various Chests',
        shop: [Shop.RINDO, Shop.BUSTOKE, Shop.PAO],
    },
    {
        type: ItemType.POLEARM,
        name: 'Power Spear',
        icon: PowerSpearIcon,
        price: '900',
        attribute: '+15 Atk',
        range: '1-2',
        equippableBy: 'KNT PLDN \n WKNT SKYL \n SKNT SBRN',
        location: 'Shops: Bustoke, Pao, Waral',
        shop: [Shop.BUSTOKE, Shop.PAO, Shop.WARAL],
    },
    {
        type: ItemType.POLEARM,
        name: 'Steel Lance',
        icon: SteelLanceIcon,
        price: '3000',
        attribute: '+18 Atk',
        range: '1',
        equippableBy: 'PLDN SKYL SBRN',
        location: 'Shops: Waral',
        shop: [Shop.WARAL],
    },
    {
        type: ItemType.POLEARM,
        name: 'Chrome Lance',
        icon: ChromeLanceIcon,
        price: '4500',
        attribute: '+22 Atk',
        range: '1',
        equippableBy: 'PLDN SKYL SBRN',
        location: 'Shops: Rudo, Prompt, Runefaust',
        shop: [Shop.RUDO, Shop.PROMPT, Shop.RUNEFAUST],
    },
    {
        type: ItemType.POLEARM,
        name: 'Halberd',
        icon: HalberdIcon,
        price: '8500',
        attribute: '+25 Atk',
        range: '1',
        equippableBy: 'PLDN SKYL SBRN',
        location: 'Chest in Dragonia, Chest in Runefaust Castle',
        description: 'Casts Bolt 1 when used',
    },
    {
        type: ItemType.POLEARM,
        name: 'Devil Lance',
        icon: DevilLanceIcon,
        price: '11000',
        attribute: '+35 Atk',
        range: '1',
        equippableBy: 'PLDN SKYL SBRN',
        location: 'Chest in Tower of the Ancients',
        description: 'Cursed: Chance to damage you',
    },
    {
        type: ItemType.POLEARM,
        name: 'Valkyrie',
        icon: ValkyrieIcon,
        price: '14000',
        attribute: '+35 Atk',
        range: '1-2',
        equippableBy: 'PLDN SKYL SBRN',
        location: 'Chest in the Battle in the Tower of the Ancients',
    },
    /* Axes */
    {
        type: ItemType.AXE,
        name: 'Hand Axe',
        icon: HandAxeIcon,
        price: '200',
        attribute: '+7 Atk',
        range: '1',
        equippableBy: 'WARR GLDR',
        location: 'Shops: Guardiana, Alterone \n Various Chests',
        shop: [Shop.GUARDIANA, Shop.ALTERONE],
    },
    {
        type: ItemType.AXE,
        name: 'Middle Axe',
        icon: MiddleAxeIcon,
        price: '600',
        attribute: '+11 Atk',
        range: '1',
        equippableBy: 'WARR GLDR',
        location: 'Shops: Bustoke, Pao',
        shop: [Shop.BUSTOKE, Shop.PAO],
    },
    {
        type: ItemType.AXE,
        name: 'Battle Axe',
        icon: BattleAxeIcon,
        price: '2600',
        attribute: '+16 Atk',
        range: '1',
        equippableBy: 'GLDR',
        location: 'Shops: Waral, Rudo',
        shop: [Shop.WARAL, Shop.RUDO],
    },
    {
        type: ItemType.AXE,
        name: 'Heat Axe',
        icon: HeatAxeIcon,
        price: '6500',
        attribute: '+22 Atk',
        range: '1',
        equippableBy: 'GLDR',
        location: 'Dropped from Lizardman',
        description: 'Casts Blaze 2 when used',
    },
    {
        type: ItemType.AXE,
        name: 'Great Axe',
        icon: GreatAxeIcon,
        price: '10000',
        attribute: '+26 Atk',
        range: '1',
        equippableBy: 'GLDR',
        location: 'Shops: Prompt, Runefaust',
        shop: [Shop.PROMPT, Shop.RUNEFAUST],
    },
    {
        type: ItemType.AXE,
        name: 'Atlas',
        icon: AtlasIcon,
        price: '15000',
        attribute: '+33 Atk',
        range: '1',
        equippableBy: 'GLDR',
        location: 'Chest in Runefaust Castle, dropped from Armed Skeleton',
        description: 'Casts Blaze 3 when used',
    },
    /* Staves */
    {
        type: ItemType.STAFF,
        name: 'Wooden Staff',
        icon: WoodenStaffIcon,
        price: '80',
        attribute: '+4 Atk',
        range: '1',
        equippableBy: 'MAGE WIZD \n HEAL VICR',
        location: 'Shops: Guardiana, Alterone \n Various chests',
        shop: [Shop.GUARDIANA, Shop.ALTERONE],
    },
    {
        type: ItemType.STAFF,
        name: 'Power Staff',
        icon: PowerStaffIcon,
        price: '500',
        attribute: '+12 Atk',
        range: '1',
        equippableBy: 'MAGE WIZD \n HEAL VICR',
        location: 'Shops: Rindo, Bustoke, Pao, Waral',
        shop: [Shop.RINDO, Shop.BUSTOKE, Shop.PAO, Shop.WARAL],
    },
    {
        type: ItemType.STAFF,
        name: 'Guardian Staff',
        icon: GuardianStaffIcon,
        price: '3200',
        attribute: '+18 Atk',
        range: '1',
        equippableBy: 'WIZD VICR',
        location: 'Shops: Rudo,',
        shop: [Shop.RUDO],
    },
    {
        type: ItemType.STAFF,
        name: 'Holy Staff',
        icon: HolyStaffIcon,
        price: '8000',
        attribute: '+26 Atk',
        range: '1',
        equippableBy: 'VICR',
        location: 'Shops: Prompt, Runefaust',
        shop: [Shop.PROMPT, Shop.RUNEFAUST],
    },
    {
        type: ItemType.STAFF,
        name: 'Demon Rod',
        icon: DemonRodIcon,
        price: '11800',
        attribute: '+35 Atk',
        range: '1',
        equippableBy: 'WIZD VICR',
        location: 'Dropped by Demon Master',
        description: 'Cursed: Chance to damage you, Casts a Drain MP Spell when used',
    },
    /* Arrows */
    {
        type: ItemType.ARROW,
        name: 'Wooden Arrow',
        icon: WoodenArrowIcon,
        price: '320',
        attribute: '+8 Atk',
        range: '2',
        equippableBy: 'ACHR BWMS \n ASKT SKNT',
        location: 'Shops: Guardiana, Alterone, Rindo, various chests',
        shop: [Shop.GUARDIANA, Shop.ALTERONE, Shop.RINDO],
    },
    {
        type: ItemType.ARROW,
        name: 'Steel Arrow',
        icon: SteelArrowIcon,
        price: '1200',
        attribute: '+13 Atk',
        range: '2',
        equippableBy: 'ACHR BWMS \n ASKT SKNT',
        location: 'Circus Tent, Shops: Bustoke, Pao',
        shop: [Shop.BUSTOKE, Shop.PAO],
    },
    {
        type: ItemType.ARROW,
        name: 'Elven Arrow',
        icon: ElvenArrowIcon,
        price: '3200',
        attribute: '+18 Atk',
        range: '2-3',
        equippableBy: 'ACHR BWMS \n ASKT SKNT',
        location: 'Shops: Waral, Rudo, Chest in Pao',
        shop: [Shop.WARAL, Shop.RUDO],
    },
    {
        type: ItemType.ARROW,
        name: 'Assault Shell',
        icon: AssaultShellIcon,
        price: '4500',
        attribute: '+27 Atk',
        range: '2-3',
        equippableBy: 'BWMS SKNT',
        location: 'Shops: Prompt',
        shop: [Shop.PROMPT],
    },
    {
        type: ItemType.ARROW,
        name: 'Buster Shot',
        icon: BusterShotIcon,
        price: '12400',
        attribute: '+35 Atk',
        range: '2-3',
        equippableBy: 'BWMS SKNT',
        location: 'Shops: Runefaust',
        shop: [Shop.RUNEFAUST],
    },
    /* Rings */
    {
        type: ItemType.RING,
        name: 'Speed Ring',
        icon: SpeedRingIcon,
        price: '1200',
        attribute: '+4 Spd',
        equippableBy: 'All',
        location: 'Found in a chest in Rindo',
        description: 'Casts Egress when used',
    },
    {
        type: ItemType.RING,
        name: 'Power Ring',
        icon: PowerRingIcon,
        price: '1000',
        attribute: '+4 Atk',
        equippableBy: 'All',
        location: 'Found in a chest in the Cavern of Darkness',
        description: 'Casts Boost when used',
    },
    {
        type: ItemType.RING,
        name: 'Shield Ring',
        icon: ShieldRingIcon,
        price: '1000',
        attribute: '+4 Def',
        equippableBy: 'All',
        location: 'Unobtainable',
        description: 'Casts Quick when used',
    },
    {
        type: ItemType.RING,
        name: 'Mobility Ring',
        icon: MobilityRingIcon,
        price: '1000',
        attribute: '+2 Mov',
        equippableBy: 'All',
        location: 'Found in a chest in Waral',
    },
    {
        type: ItemType.RING,
        name: 'White Ring',
        icon: WhiteRingIcon,
        price: '20000',
        attribute: '+6 Def',
        equippableBy: 'SDMN HERO \n HEAL VICR',
        location: 'Found in a chest in Demon Castle',
        description: 'Casts Aura 2 when used',
    },
    {
        type: ItemType.RING,
        name: 'Black Ring',
        icon: BlackRingIcon,
        price: '8500',
        attribute: '+6 Atk',
        equippableBy: 'SDMN HERO \n KNT PLDN \n WARR GLDR \n SKNT SBRN \n WKNT SKYL \n SMR NINJ',
        location: 'Found in a chest in Demon Castle',
        description: 'Cursed: Chance to stun you, Casts Blaze 2 when used',
    },
    {
        type: ItemType.RING,
        name: 'Evil Ring',
        icon: EvilRingIcon,
        price: '15000',
        attribute: '+8 Atk',
        equippableBy: 'SDMN HERO \n KNT PLDN \n WARR GLDR \n SKNT SBRN \n WKNT SKYL \n SMR NINJ',
        location: 'Found in a chest in Demon Castle',
        description: 'Cursed: Chance to damage you, Casts Bolt 3 when used',
    },
    {
        type: ItemType.RING,
        name: 'Yogurt Ring',
        icon: YogurtRingIcon,
        price: '39',
        attribute: '-/-',
        equippableBy: 'Anyone',
        location: 'Acquired when Jogurt kills an enemy',
        description: 'Makes the wearer look like Jogurt',
    },
];
