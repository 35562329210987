import React, { ReactElement } from 'react';
import { SF1Enemies } from '../data/enemies';

import Container from '../components/common/container';

import EnemyArt from '../assets/sf1/enemy_art.jpg';
import EnemyTable from '../components/enemy/enemy-table';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';

export default function EnemyPage(): ReactElement {
    return (
        <div>
            <div className='mb-8 flex flex-col gap-8 lg:flex-row'>
                <div className='w-full lg:w-2/5'>
                    <Container title='Enemies in Shining Force 1'>
                        <p className='text-dark-font'>
                            You can see a complete list of the enemies in Shining Force 1 down below or head into
                            community written guides to see more particular info and related topics to enemy behaviour
                            etc. Don't tell chapter2bats or else this place is gonna be infested with too many misses
                            and sleeps.
                        </p>
                        <div className='px-8 pt-4'>
                            <img className='sf-border w-full' src={EnemyArt} alt='Max counting Dark Dwarves' />
                        </div>
                    </Container>
                </div>
                <div className='w-full lg:w-3/5'>
                    <Container disablePadding title='Guides related to Enemies'>
                        <GuideBlock type={GuideType.GENERAL} topic='Enemies' />
                    </Container>
                </div>
            </div>
            <div className='mx-auto w-full xl:w-4/5'>
                <Container disablePadding title='Enemies in Shining Force 1'>
                    <p className='text-center font-bold text-white lg:hidden'>Swipe horizontally</p>
                    <EnemyTable enemyArray={SF1Enemies} />
                </Container>
            </div>
        </div>
    );
}
