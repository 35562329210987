import React, { ReactElement } from 'react';
import EmptyItem from '../../assets/sf1/item/empty.png';

type Props = {
    name: string | undefined;
    icon: string | undefined;
};

export default function StartingWeapon(props: Props): ReactElement {
    const { name, icon } = props;

    return (
        <div className='flex w-full justify-center gap-1'>
            <img className='mr-1 w-8' src={icon !== undefined ? icon : EmptyItem} alt='Starting Weapon' />
            <div>
                <p className='font-bold text-light-font'>Starting Weapon:</p>
                <p className='text-dark-font'>{name !== undefined ? name : 'No Weapon'}</p>
            </div>
        </div>
    );
}
