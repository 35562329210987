import React, { ReactElement, useEffect } from 'react';
import GuideEntry from '../../components/common/guide-entry';
import { Guide } from '../../types/collections';
import useGuides from '../../hooks/use-guides';
import { asyncInvoke } from '../../utils/function';
import { convertToTimeString } from '../../utils/timestring';
import { GuideType } from '../../types/enums/collections';

type Props = {
    type: GuideType;
    topic: string;
};

export default function GuideBlock(props: Props): ReactElement {
    const { type, topic } = props;

    const { guides, fetchGuides, isLoading } = useGuides();

    useEffect(asyncInvoke(fetchGuides), []);

    function getFilteredTopics(): Guide[] {
        switch (type) {
            case GuideType.GENERAL:
                return guides.filter((guide) => guide.topics?.includes(topic));
            case GuideType.CHAPTER:
                return guides.filter((guide) => guide.chapters?.includes(topic));
            case GuideType.CHARACTER:
                return guides.filter((guide) => guide.characters?.includes(topic));
        }
    }

    return (
        <>
            {isLoading ? (
                <p>Loading</p>
            ) : (
                getFilteredTopics().map((guide) => (
                    <GuideEntry
                        id={guide.id}
                        title={guide.title}
                        author={guide.user_created.first_name}
                        thumbnail={guide.thumbnail?.id}
                        createdAt={convertToTimeString(guide.date_created)}
                        updatedAt={
                            guide.date_updated
                                ? convertToTimeString(guide.date_updated)
                                : convertToTimeString(guide.date_created)
                        }
                    />
                ))
            )}
        </>
    );
}
