import { ItemType, Shop } from '../types/enums/items';
import { Item } from '../types/item';

import MedicalHerbIcon from '../assets/sf1/item/medical_herb.png';
import HealingSeedIcon from '../assets/sf1/item/healing_seed.png';
import AntidoteIcon from '../assets/sf1/item/antidote.png';
import AngelWingIcon from '../assets/sf1/item/angel_wing.png';

import PowerPotionIcon from '../assets/sf1/item/power_potion.png';
import DefensePotionIcon from '../assets/sf1/item/defense_potion.png';
import BreadOfLifeIcon from '../assets/sf1/item/bread_of_life.png';
import TurboPepperIcon from '../assets/sf1/item/turbo_pepper.png';
import LegsOfHasteIcon from '../assets/sf1/item/legs_of_haste.png';

import OrbOfLightIcon from '../assets/sf1/item/orb_of_light.png';
import MoonStoneIcon from '../assets/sf1/item/moon_stone.png';
import LunarDewIcon from '../assets/sf1/item/lunar_dew.png';
import DomingoEggIcon from '../assets/sf1/item/domingo_egg.png';

import SugoiMizugiIcon from '../assets/sf1/item/sugoi_mizugi.png';
import KituiHukuIcon from '../assets/sf1/item/kitui_huku.png';
import KindanNoHakoIcon from '../assets/sf1/item/kindan_no_hako.png';
import KenjiIcon from '../assets/sf1/item/kenji.png';
import TeppouIcon from '../assets/sf1/item/teppou.png';
import YoujiIcon from '../assets/sf1/item/youji.png';
import KakuChanIcon from '../assets/sf1/item/kaku_chan.png';

export const SF1Items: Item[] = [
    /* Restoratives */
    {
        type: ItemType.RESTORATIVE,
        name: 'Medical Herb',
        icon: MedicalHerbIcon,
        price: '10',
        description: 'Restores around 10 HP',
        location: 'Shops, various chests',
        shop: [
            Shop.GUARDIANA,
            Shop.ALTERONE,
            Shop.BUSTOKE,
            Shop.DRAGONIA,
            Shop.PAO,
            Shop.PROMPT,
            Shop.RINDO,
            Shop.RUDO,
            Shop.RUNEFAUST,
            Shop.URANBATOL,
            Shop.WARAL,
        ],
    },
    {
        type: ItemType.RESTORATIVE,
        name: 'Healing Seed',
        icon: HealingSeedIcon,
        price: '200',
        description: 'Restores around 20 HP',
        location: 'Shops, various chests',
        shop: [
            Shop.GUARDIANA,
            Shop.ALTERONE,
            Shop.BUSTOKE,
            Shop.DRAGONIA,
            Shop.PAO,
            Shop.PROMPT,
            Shop.RINDO,
            Shop.RUDO,
            Shop.RUNEFAUST,
            Shop.URANBATOL,
            Shop.WARAL,
        ],
    },
    {
        type: ItemType.RESTORATIVE,
        name: 'Antidote',
        icon: AntidoteIcon,
        price: '20',
        description: 'Cures Poison',
        location: 'Shops, various chests',
        shop: [
            Shop.BUSTOKE,
            Shop.DRAGONIA,
            Shop.PAO,
            Shop.PROMPT,
            Shop.RINDO,
            Shop.RUDO,
            Shop.RUNEFAUST,
            Shop.URANBATOL,
            Shop.WARAL,
        ],
    },
    {
        type: ItemType.RESTORATIVE,
        name: 'Angel Wing',
        icon: AngelWingIcon,
        price: '40',
        description: 'Same effect as the Egress Spell',
        location: 'Shops, various chests',
        shop: [
            Shop.GUARDIANA,
            Shop.ALTERONE,
            Shop.BUSTOKE,
            Shop.DRAGONIA,
            Shop.PAO,
            Shop.PROMPT,
            Shop.RINDO,
            Shop.RUDO,
            Shop.RUNEFAUST,
            Shop.URANBATOL,
            Shop.WARAL,
        ],
    },
    /* Statboosting */
    {
        type: ItemType.STATBOOST,
        name: 'Power Potion',
        icon: PowerPotionIcon,
        price: '500',
        description: 'Raises your Attack by 1-2. Stat increases reset upon promotion.',
        location: 'Guardiana, Alterone, Demon Castle',
    },
    {
        type: ItemType.STATBOOST,
        name: 'Defense Potion',
        icon: DefensePotionIcon,
        price: '500',
        description: 'Raises your Defense by 1-2. Stat increases reset upon promotion.',
        location: 'Guardiana, Alterone, Circus, Waral',
    },
    {
        type: ItemType.STATBOOST,
        name: 'Bread of Life',
        icon: BreadOfLifeIcon,
        price: '500',
        description: 'Raises your HP by 1-2. Stat increases reset upon promotion.',
        location: 'Alterone, Pao, Waral',
    },
    {
        type: ItemType.STATBOOST,
        name: 'Legs of Haste',
        icon: LegsOfHasteIcon,
        price: '500',
        description: 'Raises your Speed by 1-2. Stat increases reset upon promotion.',
        location: 'Pao',
    },
    {
        type: ItemType.STATBOOST,
        name: 'Turbo Pepper',
        icon: TurboPepperIcon,
        price: '1500',
        description: 'Raises your Movement by 1-2. Stat increases reset upon promotion.',
        location: 'Tower of the Ancients',
    },
    /* Statboosting */
    {
        type: ItemType.IMPORTANT,
        name: 'Orb of Light',
        icon: OrbOfLightIcon,
        price: '1000',
        description: 'Use to interact with the Spirit and Relics of Metapha',
        location: 'Cavern of Darkness',
    },
    {
        type: ItemType.IMPORTANT,
        name: 'Domingo Egg',
        icon: DomingoEggIcon,
        price: '2000',
        description: 'Used to Hatch Domingo',
        location: 'Manarina',
    },
    {
        type: ItemType.IMPORTANT,
        name: 'Moon Stone',
        icon: MoonStoneIcon,
        price: '1200',
        description: 'Give to Alchemist to creat Lunar Dew',
        location: 'Bustoke Quarry, Second Ship Battle (5-3)',
    },
    {
        type: ItemType.IMPORTANT,
        name: 'Lunar Dew',
        icon: LunarDewIcon,
        price: '1500',
        description: 'Cures Zylo from his Craze',
        location: 'Alchemist in Bustoke',
    },

    {
        type: ItemType.MISC,
        name: 'Sugoi Mizugi',
        icon: SugoiMizugiIcon,
        price: '200',
        description: 'Alternate Costume for Tao',
        location: 'Bustoke Quarry',
    },
    {
        type: ItemType.MISC,
        name: 'Kitui Huku',
        icon: KituiHukuIcon,
        price: '200',
        description: 'Alternate Costume for Anri',
        location: 'Laser Eye Battle (3-3)',
    },
    {
        type: ItemType.MISC,
        name: 'Kindan No Hako',
        icon: KindanNoHakoIcon,
        price: '65000',
        description: 'Debug Functionality',
        location: 'Not obtainable',
    },
    {
        type: ItemType.MISC,
        name: 'Kaku-chan',
        icon: KakuChanIcon,
        price: '4',
        description: 'Has no effect',
        location: 'Battle against Colossus (8-3)',
    },
    {
        type: ItemType.MISC,
        name: 'Kenji',
        icon: KenjiIcon,
        price: '4',
        description: 'Has no effect',
        location: 'Uranbatol Fortress Battle (4-2)',
    },
    {
        type: ItemType.MISC,
        name: 'Teppou',
        icon: TeppouIcon,
        price: '4',
        description: 'Has no effect',
        location: 'Second Ship Battle (5-3)',
    },
    {
        type: ItemType.MISC,
        name: 'Youji',
        icon: YoujiIcon,
        price: '4',
        description: 'Has no effect',
        location: 'Battle against Kane (6-2)',
    },
];
