import React, { ReactElement } from 'react';
import { ShiningGame } from '../../../types/enums/shining-game';
import Class from '../../../utils/classes';

import SITDLogo from '../../../assets/sitd.png';
import SF1Logo from '../../../assets/shiningforce1.png';
import SF2Logo from '../../../assets/shiningforce2.png';
import SFCDLogo from '../../../assets/shiningforcecd.png';
import SFFCLogo from '../../../assets/shiningforcefc.png';
import { Link } from 'react-router-dom';

type Props = {
    expanded: boolean;
};

export default function GameSelection(props: Props): ReactElement {
    const { expanded } = props;

    const shiningGames = [
        { name: 'Shining in the Darkness', game: ShiningGame.SITD, link: '', icon: SITDLogo, disabled: true },
        { name: 'Shining Force 1', game: ShiningGame.SF1, link: '', icon: SF1Logo, disabled: false },
        { name: 'Shining Force 2', game: ShiningGame.SF2, link: '', icon: SF2Logo, disabled: true },
        { name: 'Shining Force CD', game: ShiningGame.SFCD, link: '', icon: SFCDLogo, disabled: true },
        {
            name: 'Shining Force Gaiden: Final Conflict',
            game: ShiningGame.SFFC,
            link: '',
            icon: SFFCLogo,
            disabled: true,
        },
    ];

    return (
        <div
            className={Class.classNames(
                'relative mb-8 overflow-hidden rounded-2xl border-[1px] border-gray-700 transition-all duration-500',
                expanded ? 'h-36 opacity-100' : 'h-0 opacity-0',
            )}
        >
            <div className='absolute left-0 top-0 h-full w-full rounded-2xl bg-black opacity-20' />
            <div className='game-selection-gradient flex h-full w-full items-center justify-center space-x-4 pl-96 lg:pl-0'>
                {shiningGames.map((game) => (
                    <Link to='/shining-force-1'>
                        <div
                            className={Class.classNames(
                                'flex-col',
                                game.disabled ? 'pointer-events-none opacity-40 grayscale' : '',
                            )}
                        >
                            <div className='sf-border group relative mb-1 mt-3 h-16 w-52 cursor-pointer bg-sf-blue-medium transition-all duration-300 hover:h-20 hover:w-60 hover:bg-sf-blue-highlight'>
                                <img
                                    className='absolute left-1/2 top-1/2 w-[90%] -translate-x-1/2 -translate-y-[65%] transition'
                                    src={game.icon}
                                    alt='game logo'
                                />
                            </div>
                            <p className='text-center text-white'>{game.name}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
