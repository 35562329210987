import React, { ReactElement } from 'react';
import GameStageGround from '../../assets/game-stage.png';
import FullLogo from '../../assets/logo_full.png';

export default function GameStage(): ReactElement {
    return (
        <div className='relative h-[360px] w-full xl:h-[500px]'>
            <div className='stage-gradient mx-auto h-4/5 w-[70%] animate-bg-anim bg-black bg-base bg-repeat opacity-70' />
            <img
                className='absolute left-1/2 top-16 w-2/3 -translate-x-1/2'
                src={FullLogo}
                alt='Shining Force SR Logo'
            />
            <img
                className='absolute bottom-0 left-1/2 w-4/5 -translate-x-1/2'
                src={GameStageGround}
                alt='Shining Force 1 Menu without Simone'
            />
        </div>
    );
}
