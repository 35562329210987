export enum RangeLevel {
    SELF = 'Range0',
    ONE = 'Range1',
    ONETOTWO = 'Range1-2',
    TWO = 'Range2',
    TWOTOTHREE = 'Range2-3',
    ONETOTHREE = 'Range1-3',
}

export enum AreaLevel {
    ONE = 'Area1',
    FIVE = 'Area5',
    FOURTEEN = 'Area14',
    ALL = 'AreaAll',
}
