import React, { ReactElement } from 'react';
import Class from '../../utils/classes';

type Props = {
    extraClassNames?: string;
    title: string;
    disablePadding?: boolean;
    thumbnail: string;
    bigHeader?: boolean;
    width?: string;
    children: ReactElement | ReactElement[];
};

export default function Container(props: Props): ReactElement {
    const { extraClassNames, title, disablePadding, thumbnail, bigHeader, width, children } = props;

    return (
        <div
            className={Class.classNames(
                'overflow-hidden rounded-2xl bg-gradient-to-b from-sf-blue-highlight to-sf-blue-medium p-px',
                width || 'w-full',
                extraClassNames || '',
            )}
        >
            <div className='h-full w-full rounded-2xl bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-sf-blue-medium to-sf-blue-dark'>
                <div
                    className={Class.classNames(
                        'relative w-full rounded-t-2xl bg-[#2b49ff20] p-3',
                        bigHeader ? 'py-8' : '',
                    )}
                >
                    <h2
                        className={Class.classNames(
                            'relative z-10 font-title text-2xl text-white xl:text-3xl',
                            thumbnail === undefined ? 'text-center' : 'pl-4',
                        )}
                    >
                        {title}
                    </h2>
                    {thumbnail && (
                        <img
                            className='absolute right-0 top-0 z-0 aspect-[3/1] h-full object-cover opacity-50 gradient-mask-l-0'
                            src={thumbnail}
                            alt='header-graphic'
                        />
                    )}
                </div>
                <div className={Class.classNames(disablePadding ? '' : 'p-5')}>{children}</div>
            </div>
        </div>
    );
}

Container.defaultProps = {
    disablePadding: false,
    width: '',
    bigHeader: false,
    thumbnail: undefined,
    extraClassNames: '',
};
