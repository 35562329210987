import MaxPortrait from '../assets/sf1/char/sf1_portrait_max.png';
import MaxPromotedPortrait from '../assets/sf1/char/sf1_portrait_max_promoted.png';
import LowePortrait from '../assets/sf1/char/sf1_portrait_lowe.png';
import TaoPortrait from '../assets/sf1/char/sf1_portrait_tao.png';
import LukePortrait from '../assets/sf1/char/sf1_portrait_luke.png';
import KenPortrait from '../assets/sf1/char/sf1_portrait_ken.png';
import HansPortrait from '../assets/sf1/char/sf1_portrait_hans.png';
import GongPortrait from '../assets/sf1/char/sf1_portrait_gong.png';
import GortPortrait from '../assets/sf1/char/sf1_portrait_gort.png';
import MaePortrait from '../assets/sf1/char/sf1_portrait_mae.png';
import KhrisPortrait from '../assets/sf1/char/sf1_portrait_khris.png';
import AnriPortrait from '../assets/sf1/char/sf1_portrait_anri.png';
import ArthurPortrait from '../assets/sf1/char/sf1_portrait_arthur.png';
import BalbaroyPortrait from '../assets/sf1/char/sf1_portrait_balbaroy.png';
import AmonPortrait from '../assets/sf1/char/sf1_portrait_amon.png';
import DianePortrait from '../assets/sf1/char/sf1_portrait_diane.png';
import ZyloPortrait from '../assets/sf1/char/sf1_portrait_zylo.png';
import PellePortrait from '../assets/sf1/char/sf1_portrait_pelle.png';
import JogurtPortrait from '../assets/sf1/char/sf1_portrait_jogurt.png';
import KokichiPortrait from '../assets/sf1/char/sf1_portrait_kokichi.png';
import VankarPortrait from '../assets/sf1/char/sf1_portrait_vankar.png';
import DomingoPortrait from '../assets/sf1/char/sf1_portrait_domingo.png';
import GuntzPortrait from '../assets/sf1/char/sf1_portrait_guntz.png';
import EarnestPortrait from '../assets/sf1/char/sf1_portrait_earnest.png';
import LylePortrait from '../assets/sf1/char/sf1_portrait_lyle.png';
import BleuPortrait from '../assets/sf1/char/sf1_portrait_bleu.png';
import BleuPromotedPortrait from '../assets/sf1/char/sf1_portrait_bleu_promoted.png';
import MusashiPortrait from '../assets/sf1/char/sf1_portrait_musashi.png';
import AlefPortrait from '../assets/sf1/char/sf1_portrait_alef.png';
import TorasuPortrait from '../assets/sf1/char/sf1_portrait_torasu.png';
import AdamPortrait from '../assets/sf1/char/sf1_portrait_adam.png';
import AdamPromotedPortrait from '../assets/sf1/char/sf1_portrait_adam_promoted.png';
import HanzouPortrait from '../assets/sf1/char/sf1_portrait_hanzou.png';

import MiddleSword from '../assets/sf1/item/middle_sword.png';
import WoodenStaff from '../assets/sf1/item/wooden_staff.png';
import Spear from '../assets/sf1/item/spear.png';
import WoodenArrow from '../assets/sf1/item/wooden_arrow.png';
import HandAxe from '../assets/sf1/item/hand_axe.png';
import BronzeLance from '../assets/sf1/item/bronze_lance.png';
import ShortSword from '../assets/sf1/item/short_sword.png';
import PowerStaff from '../assets/sf1/item/power_staff.png';
import SteelArrow from '../assets/sf1/item/steel_arrow.png';
import ElvenArrow from '../assets/sf1/item/elven_arrow.png';
import Katana from '../assets/sf1/item/katana.png';
import DoomBlade from '../assets/sf1/item/doom_blade.png';

import EgressIcon from '../assets/sf1/spell/egress.png';
import BlazeIcon from '../assets/sf1/spell/blaze.png';
import FreezeIcon from '../assets/sf1/spell/freeze.png';
import BoltIcon from '../assets/sf1/spell/bolt.png';
import SleepIcon from '../assets/sf1/spell/sleep.png';
import MuddleIcon from '../assets/sf1/spell/muddle.png';
import DispelIcon from '../assets/sf1/spell/dispel.png';
import DesoulIcon from '../assets/sf1/spell/desoul.png';
import HealIcon from '../assets/sf1/spell/heal.png';
import AuraIcon from '../assets/sf1/spell/aura.png';
import DetoxIcon from '../assets/sf1/spell/detox.png';
import QuickIcon from '../assets/sf1/spell/quick.png';
import SlowIcon from '../assets/sf1/spell/slow.png';
import BoostIcon from '../assets/sf1/spell/boost.png';
import ShieldIcon from '../assets/sf1/spell/shield.png';
import {
    DoubleAttackChance,
    ElementalResistance,
    EvasionChance,
    GrowthCurve,
    Movement,
    StatusEffectChance,
    TargetPriority,
} from '../types/enums/character';
import { Character } from '../types/character';

export const SF1Characters: Character[] = [
    {
        name: 'Max',
        stats: {
            level: '1',
            hp: '12-14',
            mp: '8-9',
            atk: '6',
            def: '4',
            spd: '4',
            mov: '6',
            crit: '3%',
        },
        startitem: {
            name: 'Middle Sword',
            icon: MiddleSword,
        },
        acquisition:
            'Protagonist of the game. Max is the main character controlled by the player throughout the game and always in the Force as their leader.',
        spells: [
            {
                name: 'Egress',
                icon: EgressIcon,
                levels: [1],
            },
        ],
        classes: [
            {
                name: 'SDMN',
                image: MaxPortrait,
                classAttributes: {
                    priority: TargetPriority.HUNDRED,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.SPECIAL,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'HERO',
                image: MaxPromotedPortrait,
                classAttributes: {
                    priority: TargetPriority.HUNDRED,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.SPECIAL,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.EARLY,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Lowe',
        stats: {
            level: '1',
            hp: '11-12',
            mp: '10-11',
            atk: '6',
            def: '5',
            spd: '5',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Wooden Staff',
            icon: WoodenStaff,
        },
        acquisition: 'Joins in Guardiana before the first battle.',
        spells: [
            {
                name: 'Heal',
                icon: HealIcon,
                levels: [1, 7, 16, 22],
            },
            {
                name: 'Detox',
                icon: DetoxIcon,
                levels: [4],
            },
            {
                name: 'Slow',
                icon: SlowIcon,
                levels: [10, 19],
            },
            {
                name: 'Quick',
                icon: QuickIcon,
                levels: [13, 25],
            },
        ],
        classes: [
            {
                name: 'HEAL',
                image: LowePortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'VICR',
                image: LowePortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.EARLY,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Tao',
        stats: {
            level: '1',
            hp: '10-11',
            mp: '7',
            atk: '4',
            def: '4',
            spd: '6',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Wooden Staff',
            icon: WoodenStaff,
        },
        acquisition: 'Joins in Guardiana before the first battle.',
        spells: [
            {
                name: 'Blaze',
                icon: BlazeIcon,
                levels: [1, 4, 12, 20],
            },
            {
                name: 'Sleep',
                icon: SleepIcon,
                levels: [8],
            },
            {
                name: 'Dispel',
                icon: DispelIcon,
                levels: [16],
            },
            {
                name: 'Boost',
                icon: BoostIcon,
                levels: [27],
            },
        ],
        classes: [
            {
                name: 'MAGE',
                image: TaoPortrait,
                classAttributes: {
                    priority: TargetPriority.SEVENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'WIZD',
                image: TaoPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LATE,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.EARLY,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Luke',
        stats: {
            level: '1',
            hp: '9-10',
            mp: '0',
            atk: '9-10',
            def: '7',
            spd: '4',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Short Sword',
            icon: ShortSword,
        },
        acquisition: 'Joins in Guardiana before the first battle.',
        spells: [],
        classes: [
            {
                name: 'WARR',
                image: LukePortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.INOUT,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'GLDR',
                image: LukePortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Ken',
        stats: {
            level: '1',
            hp: '8-9',
            mp: '0',
            atk: '7',
            def: '6',
            spd: '5',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Spear',
            icon: Spear,
        },
        acquisition: 'Joins in Guardiana before the first battle.',
        spells: [],
        classes: [
            {
                name: 'KNT',
                image: KenPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.EARLY,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LATE,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'PLDN',
                image: KenPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LATE,
                        def: GrowthCurve.LATE,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.INOUT,
                    },
                },
            },
        ],
    },
    {
        name: 'Hans',
        stats: {
            level: '1',
            hp: '12-14',
            mp: '0',
            atk: '6',
            def: '5',
            spd: '6',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Wooden Arrow',
            icon: WoodenArrow,
        },
        acquisition: 'Joins in Guardiana before the first battle.',
        spells: [],
        classes: [
            {
                name: 'ACHR',
                image: HansPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FOREST,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LATE,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LATE,
                        def: GrowthCurve.LATE,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'BWMS',
                image: HansPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FOREST,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LATE,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LATE,
                    },
                },
            },
        ],
    },
    {
        name: 'Gong',
        stats: {
            level: '1',
            hp: '11-12',
            mp: '8-9',
            atk: '11-12',
            def: '4',
            spd: '6',
            mov: '5',
            crit: '3%',
        },
        startitem: undefined,
        acquisition:
            'Can be found at a cabin at the coast outside the Ancient Gate. To reach it, you have the following options: Leave/Egress out of the first battle and walk there. During the second battle, your Egress takes you directly to the cabin. Once you started the third battle, you have to finish it first before being able to reach the cabin again. Once the third battle is completed, you can walk there before and after the fourth battle. Once you complete the first chapter, this character is not recruitable anymore.',
        spells: [
            {
                name: 'Heal',
                icon: HealIcon,
                levels: [1, 8, 16, 24],
            },
            {
                name: 'Aura',
                icon: AuraIcon,
                levels: [30],
            },
        ],
        classes: [
            {
                name: 'MONK',
                image: GongPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'MMNK',
                image: GongPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LATE,
                    },
                },
            },
        ],
    },
    {
        name: 'Gort',
        stats: {
            level: '2',
            hp: '12-14',
            mp: '0',
            atk: '8-9',
            def: '7-9',
            spd: '4',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Hand Axe',
            icon: HandAxe,
        },
        acquisition:
            'Return to Guardiana after the second battle. Gort can be found in the Guardiana Pub, and will join your party. Once you complete the first chapter, this character is not recruitable anymore.',
        spells: [],
        classes: [
            {
                name: 'WARR',
                image: GortPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'GLDR',
                image: GortPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Mae',
        stats: {
            level: '2',
            hp: '11-12',
            mp: '0',
            atk: '5',
            def: '7',
            spd: '7',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Bronze Lance',
            icon: BronzeLance,
        },
        acquisition:
            'Joins automatically the force after the cutscene where her father, Lord Varios, is killed by Kane.',
        spells: [],
        classes: [
            {
                name: 'KNT',
                image: MaePortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LATE,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.EARLY,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'PLDN',
                image: MaePortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.EARLY,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Khris',
        stats: {
            level: '2',
            hp: '10-11',
            mp: '8-10',
            atk: '6',
            def: '5',
            spd: '4',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Wooden Staff',
            icon: WoodenStaff,
        },
        acquisition:
            'After being locked in the cell in Alterone, search the prison cell door, and Khris will arrive. She joins automatically the Force after freeing Max from the prison cell.',
        spells: [
            {
                name: 'Heal',
                icon: HealIcon,
                levels: [1, 5, 16, 21],
            },
            {
                name: 'Slow',
                icon: SlowIcon,
                levels: [8],
            },
            {
                name: 'Quick',
                icon: QuickIcon,
                levels: [12],
            },
            {
                name: 'Aura',
                icon: AuraIcon,
                levels: [20, 28],
            },
        ],
        classes: [
            {
                name: 'HEAL',
                image: KhrisPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'VICR',
                image: KhrisPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.EARLY,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Anri',
        stats: {
            level: '3',
            hp: '8-10',
            mp: '10-14',
            atk: '4',
            def: '4',
            spd: '7-9',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Power Staff',
            icon: PowerStaff,
        },
        acquisition:
            'Speak to Anri in Manarina upon arrival. After the cutscene with her and Nova, find Anri on top of the tower. Once you speak with her again, she will join the force. Despite common belief, this is entirely optional. Once you finish Chapter 2, this character can no longer be recruited.',
        spells: [
            {
                name: 'Blaze',
                icon: BlazeIcon,
                levels: [1, 8],
            },
            {
                name: 'Freeze',
                icon: FreezeIcon,
                levels: [5, 13, 16, 23],
            },
            {
                name: 'Muddle',
                icon: MuddleIcon,
                levels: [10],
            },
            {
                name: 'Bolt',
                icon: BoltIcon,
                levels: [19, 26],
            },
        ],
        classes: [
            {
                name: 'MAGE',
                image: AnriPortrait,
                classAttributes: {
                    priority: TargetPriority.SEVENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'WIZD',
                image: AnriPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.EARLY,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Arthur',
        stats: {
            level: '4',
            hp: '8-10',
            mp: '0',
            atk: '6',
            def: '6',
            spd: '7',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Bronze Lance',
            icon: BronzeLance,
        },
        acquisition:
            'After completing the Cavern of Darkness battle and speaking to the Spirit of the Fountain with the Orb of Light, talk to Arthur near the top of the same tower where you found Anri. He will join your party.',
        spells: [
            {
                name: 'Blaze',
                icon: BlazeIcon,
                levels: [15],
            },
            {
                name: 'Freeze',
                icon: FreezeIcon,
                levels: [20],
            },
            {
                name: 'Bolt',
                icon: BoltIcon,
                levels: [25],
            },
        ],
        classes: [
            {
                name: 'KNT',
                image: ArthurPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LATE,
                        mp: GrowthCurve.LATE,
                        atk: GrowthCurve.LATE,
                        def: GrowthCurve.LATE,
                        spd: GrowthCurve.LATE,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'PLDN',
                image: ArthurPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LATE,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LATE,
                        def: GrowthCurve.LATE,
                        spd: GrowthCurve.LATE,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Balbaroy',
        stats: {
            level: '5',
            hp: '13-17',
            mp: '0',
            atk: '9-11',
            def: '10-12',
            spd: '10-12',
            mov: '7',
            crit: '4%',
        },
        startitem: {
            name: 'Middle Sword',
            icon: MiddleSword,
        },
        acquisition: 'Joins the force automatically after the battle in the Chapel of Shade Abbey.',
        spells: [],
        classes: [
            {
                name: 'BDMN',
                image: BalbaroyPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.INOUT,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.INOUT,
                    },
                },
            },
            {
                name: 'SKYW',
                image: BalbaroyPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Amon',
        stats: {
            level: '5',
            hp: '13-17',
            mp: '0',
            atk: '7-9',
            def: '7-9',
            spd: '9-11',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Middle Sword',
            icon: MiddleSword,
        },
        acquisition:
            'After the battle in the Chapel, talk to Amon at the exit of Shade Abbey and she will join the Force. This is mandatory in order to conclude Chapter 2.',
        spells: [],
        classes: [
            {
                name: 'BDMN',
                image: AmonPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'SKYW',
                image: AmonPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LATE,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Diane',
        stats: {
            level: '6',
            hp: '12-16',
            mp: '0',
            atk: '7-9',
            def: '7',
            spd: '10-12',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Steel Arrow',
            icon: SteelArrow,
        },
        acquisition:
            'Speak to Diane in Bustoke and she will join the Force. This is optional and she can no longer be recruited after the Battle against the Laser-Eye.',
        spells: [],
        classes: [
            {
                name: 'ACHR',
                image: DianePortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FOREST,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'BWMS',
                image: DianePortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FOREST,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Zylo',
        stats: {
            level: '9',
            hp: '18-26',
            mp: '0',
            atk: '22-30',
            def: '12-16',
            spd: '11-15',
            mov: '7',
            crit: '4%',
        },
        startitem: undefined,
        acquisition:
            'After obtaining the Moon Stone, give it to the alchemist. He will then create the Lunar Dew that you can use to cure Zylo. After being cured, Zylo will join the force. The acquisition of the Moon Stone and the recruitment of Zylo is optional. This character is lost after finishing the battle against the Laser-Eye.',
        spells: [],
        classes: [
            {
                name: 'WRWF',
                image: ZyloPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.FOREST,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.EARLY,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.INOUT,
                    },
                },
            },
            {
                name: 'WFBN',
                image: ZyloPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.FOREST,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Pelle',
        stats: {
            level: '8',
            hp: '15-21',
            mp: '0',
            atk: '13-19',
            def: '16-22',
            spd: '12-16',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Bronze Lance',
            icon: BronzeLance,
        },
        acquisition:
            'After winning the Laser-Eye battle, the seemingly defeated Pelle will return and join the Force automatically.',
        spells: [],
        classes: [
            {
                name: 'KNT',
                image: PellePortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.INOUT,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.EARLY,
                        def: GrowthCurve.EARLY,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'PLDN',
                image: PellePortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.INOUT,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LATE,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Jogurt',
        stats: {
            level: '1',
            hp: '1',
            mp: '0',
            atk: '1',
            def: '1',
            spd: '1',
            mov: '7',
            crit: '1%',
        },
        startitem: undefined,
        acquisition:
            "Right after beginning Chapter 4 and starting exploring the Pao Caravan Town, head for the church on the northern cliff side. Inside the church, you can move around the tables to reach the back wall with the Church's insignia. Once reaching that side of the building, Jogurt should come barely into view where you can see him stretching and lying down. Once you witnessed the event, Jogurt has joined your Force without message nor jingle. Beware: He cannot be recruited anymore once the Caravan moved on, so make sure not to send it off before recruiting him if you wish to do so.",
        spells: [],
        classes: [
            {
                name: 'YGRT',
                image: JogurtPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.FIFTY,
                    muddleChance: StatusEffectChance.FIFTY,
                    slowChance: StatusEffectChance.FIFTY,
                    SleepDesoulChance: StatusEffectChance.FIFTY,
                    blazeResistance: ElementalResistance.FIFTY,
                    freezeResistance: ElementalResistance.FIFTY,
                    boltResistance: ElementalResistance.FIFTY,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Kokichi',
        stats: {
            level: '7',
            hp: '13-17',
            mp: '0',
            atk: '10-14',
            def: '9-11',
            spd: '10-12',
            mov: '6',
            crit: '3%',
        },
        startitem: {
            name: 'Bronze Lance',
            icon: BronzeLance,
        },
        acquisition:
            'While in Bustoke in Chapter 3, after the battle in the quarry, find Kokichi in one of the homes, working on his flight equipment. Search the machinery in front of him and then talk to him. He will want to show you his invention and moves out. Follow him and watch his attempt at flight. This is all you have to do in Chapter 3. Later on, in Chapter 4, when you have sent of the Pao Caravan, Kokichi will arrive and join the force.',
        spells: [],
        classes: [
            {
                name: 'WKNT',
                image: KokichiPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'SKYL',
                image: KokichiPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Vankar',
        stats: {
            level: '8',
            hp: '25-33',
            mp: '0',
            atk: '10-12',
            def: '10-12',
            spd: '10-12',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Bronze Lance',
            icon: BronzeLance,
        },
        acquisition:
            'After the Pao Caravan departs, Vankar will be left behind and stand in the fields where the Caravan once was. Talk to him and he joins your Force. Once you finish Chapter 4, this character can no longer be recruited.',
        spells: [],
        classes: [
            {
                name: 'KNT',
                image: VankarPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.EARLY,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'PLDN',
                image: VankarPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Domingo',
        stats: {
            level: '1',
            hp: '15-17',
            mp: '15-17',
            atk: '10-11',
            def: '15-17',
            spd: '18-21',
            mov: '5',
            crit: '4%',
        },
        startitem: undefined,
        acquisition:
            'In Chapter 2, you can find the Domingo Egg in a weird golem-looking machinery in Manarina. In Chapter 4, keep the item until you reach the Pao Caravan after the battle against Elliot. In a tent southwest of the sheep pen, one of the nomads has a hatching machine. Talk to him and if you have the Domingo Egg in anyones inventory, he hatches it and Domingo is born. Talk with Domingo and he joins the Force.',
        spells: [
            {
                name: 'Freeze',
                icon: FreezeIcon,
                levels: [1, 4, 13, 26],
            },
            {
                name: 'Muddle',
                icon: MuddleIcon,
                levels: [7],
            },
            {
                name: 'Desoul',
                icon: DesoulIcon,
                levels: [17],
            },
            {
                name: 'Boost',
                icon: BoostIcon,
                levels: [20],
            },
        ],
        classes: [
            {
                name: 'MGCR',
                image: DomingoPortrait,
                classAttributes: {
                    priority: TargetPriority.SEVENTYFIVE,
                    movementType: Movement.HOVERING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Guntz',
        stats: {
            level: '8',
            hp: '12-16',
            mp: '0',
            atk: '13-17',
            def: '14-20',
            spd: '10-14',
            mov: '4',
            crit: '3%',
        },
        startitem: {
            name: 'Bronze Lance',
            icon: BronzeLance,
        },
        acquisition:
            'When visiting the Pao Caravan after the battle against Elliot, you will find Guntz in the sheep pen. Talk to him and he joins your Force. This character is no longer recruitable once Chapter 4 is finished.',
        spells: [],
        classes: [
            {
                name: 'SKNT',
                image: GuntzPortrait,
                classAttributes: {
                    priority: TargetPriority.HUNDRED,
                    movementType: Movement.MECHANICAL,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.EARLY,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'SBRN',
                image: GuntzPortrait,
                classAttributes: {
                    priority: TargetPriority.HUNDRED,
                    movementType: Movement.MECHANICAL,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.INOUT,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.INOUT,
                    },
                },
            },
        ],
    },
    {
        name: 'Earnest',
        stats: {
            level: '8',
            hp: '16-22',
            mp: '0',
            atk: '10-14',
            def: '10-12',
            spd: '10-14',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Bronze Lance',
            icon: BronzeLance,
        },
        acquisition:
            'If you finish the battle at Uranbatol Fortress by defeating the Hellhound at the left door last, you will find Earnest on the way into the Fortress where he joins your Force automatically. If you finish the battle by killing the Artillery in front of the right door, you will need to backtrack around the inner part of the fortress to talk to him where then joins your Force. In either case, this is mandatory in order to progress and have the guard open the harbor for you to face Balbazak.',
        spells: [],
        classes: [
            {
                name: 'KNT',
                image: EarnestPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.INOUT,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'PLDN',
                image: EarnestPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.INOUT,
                        def: GrowthCurve.INOUT,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Lyle',
        stats: {
            level: '8',
            hp: '12-16',
            mp: '0',
            atk: '11-15',
            def: '7-9',
            spd: '8-10',
            mov: '7',
            crit: '3%',
        },
        startitem: {
            name: 'Elven Arrow',
            icon: ElvenArrow,
        },
        acquisition:
            'Lyle can be found at the start of Chapter 6 in the town of Rudo. Enter the westmost building and reach the roof where you find him standing around. Talk to him and he joins the Force. Once Chapter 6 is concluded, this character can no longer be recruited.',
        spells: [],
        classes: [
            {
                name: 'ASKT',
                image: LylePortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'SKNT',
                image: LylePortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.MOUNTED,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Bleu',
        stats: {
            level: '9',
            hp: '16-22',
            mp: '0',
            atk: '20-28',
            def: '14-20',
            spd: '8-10',
            mov: '5',
            crit: '3%',
        },
        startitem: undefined,
        acquisition:
            'Find and talk to Bleu in Dragonia. After a mandatory cutscene in which he torches an enemy, he will join the Force.',
        spells: [],
        classes: [
            {
                name: 'DRGN',
                image: BleuPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LATE,
                    },
                },
            },
            {
                name: 'GRDR',
                image: BleuPromotedPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.FLYING,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.TWENTYFIVE,
                    muddleChance: StatusEffectChance.WEAKNESS,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Musashi',
        stats: {
            level: '10',
            hp: '48-56',
            mp: '0',
            atk: '24-32',
            def: '37-45',
            spd: '24-32',
            mov: '5',
            crit: '13%-17%',
        },
        startitem: {
            name: 'Katana',
            icon: Katana,
        },
        acquisition:
            'In Chapter 7, once you broke out of the prison in Prompt, you can check a little white note hanging from one of the houses exteriors on top of the cliff side. Once you searched the note, Musashi will have the joined Force. This is no longer doable once you finish Chapter 7.',
        spells: [],
        classes: [
            {
                name: 'SMR',
                image: MusashiPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.INOUT,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Alef',
        stats: {
            level: '15',
            hp: '14-19',
            mp: '25-33',
            atk: '8-10',
            def: '10-12',
            spd: '14-20',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Power Staff',
            icon: PowerStaff,
        },
        acquisition:
            'Alef joins the Force automatically on the way up in the Tower of the Ancients together with Torasu in a mandatory cutscene.',
        spells: [
            {
                name: 'Blaze',
                icon: BlazeIcon,
                levels: [1, 1],
            },
            {
                name: 'Freeze',
                icon: FreezeIcon,
                levels: [1, 1],
            },
            {
                name: 'Bolt',
                icon: BoltIcon,
                levels: [1, 19, 22, 30],
            },
            {
                name: 'Desoul',
                icon: DesoulIcon,
                levels: [26],
            },
        ],
        classes: [
            {
                name: 'MAGE',
                image: AlefPortrait,
                classAttributes: {
                    priority: TargetPriority.SEVENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'WIZD',
                image: AlefPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Torasu',
        stats: {
            level: '16',
            hp: '19-26',
            mp: '21-29',
            atk: '11-15',
            def: '11-15',
            spd: '12-16',
            mov: '5',
            crit: '3%',
        },
        startitem: {
            name: 'Power Staff',
            icon: PowerStaff,
        },
        acquisition:
            'Torasu joins the Force automatically on the way up in the Tower of the Ancients together with Alef in a mandatory cutscene.',
        spells: [
            {
                name: 'Heal',
                icon: HealIcon,
                levels: [1, 1],
            },
            {
                name: 'Detox',
                icon: DetoxIcon,
                levels: [1],
            },
            {
                name: 'Shield',
                icon: ShieldIcon,
                levels: [1],
            },
            {
                name: 'Aura',
                icon: AuraIcon,
                levels: [1, 20, 24, 28],
            },
        ],
        classes: [
            {
                name: 'HEAL',
                image: TorasuPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.INOUT,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'VICR',
                image: TorasuPortrait,
                classAttributes: {
                    priority: TargetPriority.TWENTYFIVE,
                    movementType: Movement.STANDARD,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LATE,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Adam',
        stats: {
            level: '10',
            hp: '27-35',
            mp: '0',
            atk: '17-25',
            def: '19-27',
            spd: '10-14',
            mov: '4',
            crit: '3%',
        },
        startitem: undefined,
        acquisition:
            'Adam awaits you in the lost civilization of Metapha. After you spoke to the Spirit of the Spring, you can talk to Adam and he automatically joins your Force. You can either Egress out of the battle in order to add him to your active Force or wait until after you have defeated Chaos.',
        spells: [],
        classes: [
            {
                name: 'RBT',
                image: AdamPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MECHANICAL,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.EARLY,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
            {
                name: 'CYBG',
                image: AdamPromotedPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.MECHANICAL,
                    dblAttack: DoubleAttackChance.STANDARD,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
    {
        name: 'Hanzou',
        stats: {
            level: '10',
            hp: '38-46',
            mp: '20-28',
            atk: '20-28',
            def: '32-40',
            spd: '34-42',
            mov: '7',
            crit: '7%-9%',
        },
        startitem: {
            name: 'Doom Blade',
            icon: DoomBlade,
        },
        acquisition:
            'In Chapter 8, right at the entrance of the Runefaust town, you will find a bush that carries a single flower. Search the flower and you will find Hanzou and he joins your Force.',
        spells: [
            {
                name: 'Sleep',
                icon: SleepIcon,
                levels: [1],
            },
            {
                name: 'Dispel',
                icon: DispelIcon,
                levels: [1],
            },
            {
                name: 'Desoul',
                icon: DesoulIcon,
                levels: [1],
            },
            {
                name: 'Shield',
                icon: ShieldIcon,
                levels: [15],
            },
        ],
        classes: [
            {
                name: 'NINJ',
                image: HanzouPortrait,
                classAttributes: {
                    priority: TargetPriority.BASE,
                    movementType: Movement.FOREST,
                    dblAttack: DoubleAttackChance.SPECIAL,
                    evasion: EvasionChance.BASE,
                    muddleChance: StatusEffectChance.BASE,
                    slowChance: StatusEffectChance.BASE,
                    SleepDesoulChance: StatusEffectChance.BASE,
                    blazeResistance: ElementalResistance.BASE,
                    freezeResistance: ElementalResistance.BASE,
                    boltResistance: ElementalResistance.BASE,
                    growthCurves: {
                        hp: GrowthCurve.LINEAR,
                        mp: GrowthCurve.LINEAR,
                        atk: GrowthCurve.LINEAR,
                        def: GrowthCurve.LINEAR,
                        spd: GrowthCurve.LINEAR,
                        crit: GrowthCurve.LINEAR,
                    },
                },
            },
        ],
    },
];
