import React, { ReactElement } from 'react';

import Container from '../components/common/container';
import useLocale from '../hooks/use-locale';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';
import OtherArt from '../assets/sf1/other_art.jpg';

export default function OtherPage(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='mx-auto flex flex-col items-center gap-8'>
            <div className='mx-auto flex w-full items-center justify-center gap-8 lg:w-4/5'>
                <Container disablePadding title={'Other Topics in Shining Force 1'}>
                    <div className='flex flex-col items-center lg:flex-row lg:space-x-5'>
                        <img className='w-80' src={OtherArt} alt='Max counting Dark Dwarves' />
                        <p className='p-4 text-base text-dark-font xl:text-lg'>
                            Some of our guides might not be directly related to any of the specific topics you can
                            choose from on Shining Force SR. You'll find them here instead. Oh and Jogurt, Jogurt is
                            also here. Why? I don't know. He says hello.
                        </p>
                    </div>
                </Container>
            </div>
            <div className='w-full lg:w-4/5'>
                <Container disablePadding title={`Guides about other topics`}>
                    <GuideBlock type={GuideType.GENERAL} topic='Other' />
                </Container>
            </div>
        </div>
    );
}
