import { useState } from 'react';
import { ServiceError } from '../common/error';
import { Leaderboard } from '../types/leaderboard';

const API_BASEURL = 'https://www.speedrun.com/api/v1/leaderboards/shining_force_1/category/Any';
const FIELDS = '?top=10&embed=players';

type LeaderboardApi = {
    leaderboard: Leaderboard | undefined;
    fetchLeaderboard: () => Promise<void>;
    isLoading: boolean;
};

export enum LeaderboardErrorType {
    UNKNOWN = 'UNKNOWN',
    NETWORK_ERROR = 'NETWORK_ERROR',
    UNAUTHORIZED = 'UNAUTHORIZED',
    EMPTY_LEADERBOARD = 'EMPTY_LEADERBOARD',
}

export class LeaderboardError extends ServiceError<LeaderboardErrorType> {}

export default function useLeaderboard(): LeaderboardApi {
    const [leaderboard, setLeaderboard] = useState<Leaderboard>();
    const [isLoading, setIsLoading] = useState(false);

    async function fetchLeaderboard(): Promise<void> {
        setIsLoading(true);

        try {
            const res = await fetch(`${API_BASEURL}${FIELDS}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                throw new LeaderboardError(LeaderboardErrorType.NETWORK_ERROR, 'Failed to fetch leaderboard');
            }

            const jsonData = await res.json();

            setLeaderboard(jsonData.data);
        } catch (error: Error | any) {
            console.error(error.message);
            throw new LeaderboardError(LeaderboardErrorType.NETWORK_ERROR, error.message);
        }

        setIsLoading(false);
    }

    return {
        leaderboard,
        fetchLeaderboard,
        isLoading,
    };
}
