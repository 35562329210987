import React, { ReactElement } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import CommonLayout from './components/layout/layout';
import LandingPage from './pages/landing-page';
import ShiningForce1Page from './pages/shining-force-1';
import CharacterPage from './pages/character-page';
import ChapterPage from './pages/chapter-page';
import GuidePage from './pages/guide-page';
import EnemyPage from './pages/enemy-page';
import ItemPage from './pages/item-page';
import SpellPage from './pages/spell-page';
import GlitchesPage from './pages/glitches-page';
import BeginnerPage from './pages/beginner-page';
import MechanicsPage from './pages/mechanics-page';
import OtherPage from './pages/other-page';
import ShiningFriendsPage from './pages/shining-friends-page';

export default function App(): ReactElement {
    return (
        <BrowserRouter>
            <CommonLayout>
                <Routes>
                    <Route path='/' element={<LandingPage />} />
                    <Route path='/shining-force-1' element={<ShiningForce1Page />} />
                    <Route path='/shining-force-1/guide/:id' element={<GuidePage />} />
                    <Route path='/shining-force-1/character/:id' element={<CharacterPage />} />
                    <Route path='/shining-force-1/chapter/:id' element={<ChapterPage />} />
                    <Route path='/shining-force-1/enemies' element={<EnemyPage />} />
                    <Route path='/shining-force-1/items' element={<ItemPage />} />
                    <Route path='/shining-force-1/spells' element={<SpellPage />} />
                    <Route path='/shining-force-1/beginner' element={<BeginnerPage />} />
                    <Route path='/shining-force-1/glitches' element={<GlitchesPage />} />
                    <Route path='/shining-force-1/mechanics' element={<MechanicsPage />} />
                    <Route path='/shining-force-1/other' element={<OtherPage />} />
                    <Route path='/shining-friends' element={<ShiningFriendsPage />} />
                </Routes>
            </CommonLayout>
        </BrowserRouter>
    );
}
