import React, { ReactElement } from 'react';
import FullLogo from '../../assets/logo_full.png';
import Jogurt from '../../assets/jogurt.gif';
import Domingo from '../../assets/domingo.gif';
import Kiwi from '../../assets/kiwi.gif';

export default function Footer(): ReactElement {
    return (
        <div className='relative h-32 w-full lg:h-56'>
            <div className='absolute h-full w-full bg-gradient-to-b from-transparent to-black opacity-80' />
            <div className='relative z-10 flex h-full w-full max-w-[768px] items-center justify-between px-4 lg:mx-auto lg:w-[960px] lg:max-w-none xl:w-[1200px]'>
                <div className='flex h-full items-end gap-4'>
                    <img className='w-12 lg:w-16' src={Jogurt} alt='Jogurt' />
                    <img className='w-12 lg:w-16' src={Domingo} alt='Domingo' />
                    <img className='w-12 lg:w-16' src={Kiwi} alt='Kiwi' />
                </div>
                <div className='flex flex-col items-center'>
                    <img className='mb-1 w-32' src={FullLogo} alt='Shining Force SR Logo' />
                    <p className='text-center text-xs text-white xl:text-base'>2023 - Created by: Nakuri</p>
                </div>
            </div>
        </div>
    );
}
