import GoblinSprite from '../assets/sf1/enemy/goblin.png';
import DarkDwarfSprite from '../assets/sf1/enemy/darkdwarf.png';
import RuneKnightSprite from '../assets/sf1/enemy/runeknight.png';
import GiantBatSprite from '../assets/sf1/enemy/giantbat.png';
import DarkMageSprite from '../assets/sf1/enemy/darkmage.png';
import SniperSprite from '../assets/sf1/enemy/sniper.png';
import ZombieSprite from '../assets/sf1/enemy/zombie.png';
import SkeletonSprite from '../assets/sf1/enemy/skeleton.png';
import EvilPuppetSprite from '../assets/sf1/enemy/evilpuppet.png';
import MannequinSprite from '../assets/sf1/enemy/mannequin.png';
import DireClownSprite from '../assets/sf1/enemy/direclown.png';
import MarionetteSprite from '../assets/sf1/enemy/marionette.png';
import GhoulSprite from '../assets/sf1/enemy/ghoul.png';
import DarkElfSprite from '../assets/sf1/enemy/darkelf.png';
import DarkPriestSprite from '../assets/sf1/enemy/darkpriest.png';
import MasterMageSprite from '../assets/sf1/enemy/mastermage.png';
import LizardManSprite from '../assets/sf1/enemy/lizardman.png';
import PegasusKnightSprite from '../assets/sf1/enemy/pegasusknight.png';
import SilverKnightSprite from '../assets/sf1/enemy/silverknight.png';
import LaserEyeSprite from '../assets/sf1/enemy/lasereye.png';
import ArtillerySprite from '../assets/sf1/enemy/artillery.png';
import ElliotSprite from '../assets/sf1/enemy/elliott.png';
import HellHoundSprite from '../assets/sf1/enemy/hellhound.png';
import BalbazakSprite from '../assets/sf1/enemy/balbazak.png';
import SeaBatSprite from '../assets/sf1/enemy/seabat.png';
import ConchSprite from '../assets/sf1/enemy/conch.png';
import ShellfishSprite from '../assets/sf1/enemy/shellfish.png';
import WormSprite from '../assets/sf1/enemy/worm.png';
import GargoyleSprite from '../assets/sf1/enemy/gargoyle.png';
import GolemSprite from '../assets/sf1/enemy/golem.png';
import DurahanSprite from '../assets/sf1/enemy/durahan.png';
import HighPriestSprite from '../assets/sf1/enemy/highpriest.png';
import CainSprite from '../assets/sf1/enemy/cain.png';
import BowRiderSprite from '../assets/sf1/enemy/bowrider.png';
import BelialSprite from '../assets/sf1/enemy/belial.png';
import MishaelaSprite from '../assets/sf1/enemy/mishaela.png';
import TorchEyeSprite from '../assets/sf1/enemy/torcheye.png';
import MinotaurSprite from '../assets/sf1/enemy/minotaur.png';
import WyvernSprite from '../assets/sf1/enemy/wyvern.png';
import JetSprite from '../assets/sf1/enemy/jet.png';
import IceWormSprite from '../assets/sf1/enemy/iceworm.png';
import SteelClawSprite from '../assets/sf1/enemy/steelclaw.png';
import DemonMasterSprite from '../assets/sf1/enemy/demonmaster.png';
import ChaosSprite from '../assets/sf1/enemy/chaos.png';
import HorsemanSprite from '../assets/sf1/enemy/horseman.png';
import ArmedSkeletonSprite from '../assets/sf1/enemy/armedskeleton.png';
import CerberusSprite from '../assets/sf1/enemy/cerberus.png';
import ChimeraSprite from '../assets/sf1/enemy/chimaera.png';
import BlueDragonSprite from '../assets/sf1/enemy/bluedragon.png';
import RamladuSprite from '../assets/sf1/enemy/ramladu.png';
import ColossusSprite from '../assets/sf1/enemy/colossus.png';
import DarksolSprite from '../assets/sf1/enemy/darksol.png';
import DarkDragonSprite from '../assets/sf1/enemy/darkdragon.png';

import MiddleSword from '../assets/sf1/item/middle_sword.png';
import WoodenStaff from '../assets/sf1/item/wooden_staff.png';
import WoodenArrow from '../assets/sf1/item/wooden_arrow.png';
import HandAxe from '../assets/sf1/item/hand_axe.png';
import BronzeLance from '../assets/sf1/item/bronze_lance.png';
import ShortSword from '../assets/sf1/item/short_sword.png';
import PowerStaff from '../assets/sf1/item/power_staff.png';
import SteelArrow from '../assets/sf1/item/steel_arrow.png';
import HolyStaff from '../assets/sf1/item/holy_staff.png';
import BusterShot from '../assets/sf1/item/buster_shot.png';
import MiddleAxe from '../assets/sf1/item/middle_axe.png';
import HeatAxe from '../assets/sf1/item/heat_axe.png';
import BroadSword from '../assets/sf1/item/broad_sword.png';
import LongSword from '../assets/sf1/item/long_sword.png';
import SteelSword from '../assets/sf1/item/steel_sword.png';
import GreatAxe from '../assets/sf1/item/great_axe.png';
import DemonRod from '../assets/sf1/item/demon_rod.png';
import SwordOfDarkness from '../assets/sf1/item/sword_of_darkness.png';
import AssaultShell from '../assets/sf1/item/assault_shell.png';

export const SF1Enemies = [
    {
        id: 'Chapter1',
        title: 'Chapter 1',
        enemies: [
            {
                name: 'Goblin',
                img: GoblinSprite,
                weapon: ShortSword,
                hp: '12',
                mp: '0',
                atk: '9',
                def: '6',
                mov: '5',
                spd: '5',
                spells: '',
                description: '',
            },
            {
                name: 'Dark Dwarf',
                img: DarkDwarfSprite,
                weapon: HandAxe,
                hp: '12',
                mp: '0',
                atk: '12',
                def: '8',
                mov: '4',
                spd: '5',
                spells: '',
                description: '',
            },
            {
                name: 'Rune Knight',
                img: RuneKnightSprite,
                weapon: BronzeLance,
                hp: '14',
                mp: '0',
                atk: '16',
                def: '7',
                mov: '7',
                spd: '7',
                spells: '',
                description: '',
            },
            {
                name: 'Giant Bat',
                img: GiantBatSprite,
                weapon: '',
                hp: '14',
                mp: '0',
                atk: '11',
                def: '6',
                mov: '7',
                spd: '9',
                spells: '',
                description: 'Attacks can apply Sleep',
            },
            {
                name: 'Dark Mage',
                img: DarkMageSprite,
                weapon: WoodenStaff,
                hp: '13',
                mp: '26',
                atk: '10',
                def: '6',
                mov: '5',
                spd: '9',
                spells: 'Blaze 2',
                description: '',
            },
            {
                name: 'Sniper',
                img: SniperSprite,
                weapon: WoodenArrow,
                hp: '13',
                mp: '0',
                atk: '15',
                def: '5',
                mov: '5',
                spd: '7',
                spells: '',
                description: 'Range 2',
            },
        ],
    },
    {
        id: 'Chapter2',
        title: 'Chapter 2',
        enemies: [
            {
                name: 'Dark Dwarf',
                img: DarkDwarfSprite,
                weapon: HandAxe,
                hp: '12',
                mp: '0',
                atk: '12',
                def: '8',
                mov: '4',
                spd: '5',
                spells: '',
                description: '',
            },
            {
                name: 'Giant Bat',
                img: GiantBatSprite,
                weapon: '',
                hp: '14',
                mp: '0',
                atk: '11',
                def: '6',
                mov: '7',
                spd: '9',
                spells: '',
                description: 'Attacks can apply Sleep',
            },
            {
                name: 'Dark Mage',
                img: DarkMageSprite,
                weapon: WoodenStaff,
                hp: '13',
                mp: '26',
                atk: '10',
                def: '6',
                mov: '5',
                spd: '9',
                spells: 'Blaze 2',
                description: '',
            },
            {
                name: 'Sniper',
                img: SniperSprite,
                weapon: WoodenArrow,
                hp: '13',
                mp: '0',
                atk: '15',
                def: '5',
                mov: '5',
                spd: '7',
                spells: '',
                description: 'Range 2',
            },
            {
                name: 'Zombie',
                img: ZombieSprite,
                weapon: '',
                hp: '15',
                mp: '0',
                atk: '18',
                def: '13',
                mov: '5',
                spd: '7',
                spells: '',
                description: 'Attacks can apply Poison',
            },
            {
                name: 'Skeleton',
                img: SkeletonSprite,
                weapon: MiddleSword,
                hp: '15',
                mp: '0',
                atk: '21',
                def: '16',
                mov: '6',
                spd: '7',
                spells: '',
                description: '',
            },
            {
                name: 'Mannequin',
                img: MannequinSprite,
                weapon: '',
                hp: '16',
                mp: '0',
                atk: '16',
                def: '10',
                mov: '5',
                spd: '8',
                spells: '',
                description: '',
            },
            {
                name: 'Evil Puppet',
                img: EvilPuppetSprite,
                weapon: '',
                hp: '14',
                mp: '15',
                atk: '14',
                def: '10',
                mov: '5',
                spd: '7',
                spells: 'Freeze 1',
                description: 'Chance to attack with Ominous Incantation',
            },
            {
                name: 'Dire Clown',
                img: DireClownSprite,
                weapon: '',
                hp: '15',
                mp: '0',
                atk: '18',
                def: '11',
                mov: '5',
                spd: '7',
                spells: '',
                description: '',
            },
            {
                name: 'Marionette',
                img: MarionetteSprite,
                weapon: '',
                hp: '35',
                mp: '??',
                atk: '25',
                def: '13',
                mov: '6',
                spd: '13',
                spells: 'Freeze 3',
                description: 'Boss Regeneration Script',
            },
            {
                name: 'Ghoul',
                img: GhoulSprite,
                weapon: '',
                hp: '25',
                mp: '0',
                atk: '23',
                def: '14',
                mov: '6',
                spd: '9',
                spells: '',
                description: '',
            },
        ],
    },
    {
        id: 'Chapter3',
        title: 'Chapter 3',
        enemies: [
            {
                name: 'Skeleton',
                img: SkeletonSprite,
                weapon: MiddleSword,
                hp: '15',
                mp: '0',
                atk: '21',
                def: '16',
                mov: '6',
                spd: '7',
                spells: '',
                description: 'Carries a Medical Herb which they may use when damaged',
            },
            {
                name: 'Dark Elf',
                img: DarkElfSprite,
                weapon: SteelArrow,
                hp: '16',
                mp: '0',
                atk: '26',
                def: '9',
                mov: '6',
                spd: '10',
                spells: '',
                description: 'Range 2',
            },
            {
                name: 'Dark Priest',
                img: DarkPriestSprite,
                weapon: PowerStaff,
                hp: '16',
                mp: '25',
                atk: '21',
                def: '9',
                mov: '5',
                spd: '10',
                spells: 'Heal 1',
                description: '',
            },
            {
                name: 'Master Mage',
                img: MasterMageSprite,
                weapon: HolyStaff,
                hp: '22',
                mp: '32',
                atk: '33',
                def: '13',
                mov: '5',
                spd: '26',
                spells: 'Freeze 2',
                description: 'Regenerates 5 HP per turn',
            },
            {
                name: 'Pegasus Knight',
                img: PegasusKnightSprite,
                weapon: BronzeLance,
                hp: '18',
                mp: '0',
                atk: '21',
                def: '16',
                mov: '7',
                spd: '12',
                spells: '',
                description: '',
            },
            {
                name: 'Lizardman',
                img: LizardManSprite,
                weapon: MiddleAxe,
                hp: '20',
                mp: '0',
                atk: '24',
                def: '12',
                mov: '6',
                spd: '12',
                spells: '',
                description: '',
            },
            {
                name: 'Silver Knight',
                img: SilverKnightSprite,
                weapon: BronzeLance,
                hp: '16',
                mp: '0',
                atk: '18',
                def: '12',
                mov: '7',
                spd: '11',
                spells: '',
                description: '',
            },
            {
                name: 'Laser Eye',
                img: LaserEyeSprite,
                weapon: '',
                hp: '30',
                mp: '0',
                atk: '14',
                def: '19',
                mov: '0',
                spd: '27',
                spells: '',
                description: 'Scripted to charge up attack on bridge.',
            },
        ],
    },
    {
        id: 'Chapter4',
        title: 'Chapter 4',
        enemies: [
            {
                name: 'Dark Priest',
                img: DarkPriestSprite,
                weapon: PowerStaff,
                hp: '16',
                mp: '25',
                atk: '21',
                def: '9',
                mov: '5',
                spd: '10',
                spells: 'Heal 1',
                description: '',
            },
            {
                name: 'Lizardman',
                img: LizardManSprite,
                weapon: MiddleAxe,
                hp: '20',
                mp: '0',
                atk: '24',
                def: '12',
                mov: '6',
                spd: '12',
                spells: '',
                description: '',
            },
            {
                name: 'Lizardman',
                img: LizardManSprite,
                weapon: HeatAxe,
                hp: '20',
                mp: '0',
                atk: '24',
                def: '12',
                mov: '6',
                spd: '12',
                spells: '',
                description: '',
            },
            {
                name: 'Pegasus Knight',
                img: PegasusKnightSprite,
                weapon: BronzeLance,
                hp: '18',
                mp: '0',
                atk: '21',
                def: '16',
                mov: '7',
                spd: '12',
                spells: '',
                description: '',
            },
            {
                name: 'Silver Knight',
                img: SilverKnightSprite,
                weapon: BronzeLance,
                hp: '16',
                mp: '0',
                atk: '18',
                def: '12',
                mov: '7',
                spd: '11',
                spells: '',
                description: '',
            },
            {
                name: 'Artillery',
                img: ArtillerySprite,
                weapon: '',
                hp: '14',
                mp: '0',
                atk: '24',
                def: '15',
                mov: '4',
                spd: '14',
                spells: '',
                description: 'Range 2',
            },
            {
                name: 'Elliott',
                img: ElliotSprite,
                weapon: LongSword,
                hp: '60',
                mp: '0',
                atk: '32',
                def: '17',
                mov: '5',
                spd: '16',
                spells: '',
                description: 'Boss Regeneration Script',
            },
            {
                name: 'Hellhound',
                img: HellHoundSprite,
                weapon: '',
                hp: '19',
                mp: '0',
                atk: '10',
                def: '13',
                mov: '7',
                spd: '13',
                spells: '',
                description: 'Attacks occasionally with Fire Breath Attack',
            },
            {
                name: 'Evil Puppet',
                img: EvilPuppetSprite,
                weapon: PowerStaff,
                hp: '14',
                mp: '15',
                atk: '14',
                def: '10',
                mov: '5',
                spd: '7',
                spells: 'Heal 1',
                description: 'Wears Shield Ring',
            },
            {
                name: 'Balbazak',
                img: BalbazakSprite,
                weapon: SteelSword,
                hp: '65',
                mp: '0',
                atk: '34',
                def: '18',
                mov: '5',
                spd: '20',
                spells: '',
                description: 'Boss Regeneration Script',
            },
        ],
    },
    {
        id: 'Chapter5',
        title: 'Chapter 5',
        enemies: [
            {
                name: 'Skeleton',
                img: SkeletonSprite,
                weapon: MiddleSword,
                hp: '15',
                mp: '0',
                atk: '21',
                def: '16',
                mov: '6',
                spd: '7',
                spells: '',
                description: 'Carries a Medical Herb which they may use when damaged',
            },
            {
                name: 'Pegasus Knight',
                img: PegasusKnightSprite,
                weapon: BronzeLance,
                hp: '18',
                mp: '0',
                atk: '21',
                def: '16',
                mov: '7',
                spd: '12',
                spells: '',
                description: '',
            },
            {
                name: 'Hellhound',
                img: HellHoundSprite,
                weapon: '',
                hp: '19',
                mp: '0',
                atk: '10',
                def: '13',
                mov: '7',
                spd: '13',
                spells: '',
                description: 'May attack with Fire Breath',
            },
            {
                name: 'Seabat',
                img: SeaBatSprite,
                weapon: '',
                hp: '22',
                mp: '0',
                atk: '20',
                def: '14',
                mov: '7',
                spd: '14',
                spells: '',
                description: '',
            },
            {
                name: 'Conch',
                img: ConchSprite,
                weapon: '',
                hp: '21',
                mp: '0',
                atk: '20',
                def: '15',
                mov: '6',
                spd: '16',
                spells: '',
                description: 'Attacks can apply Poison',
            },
            {
                name: 'Shellfish',
                img: ShellfishSprite,
                weapon: '',
                hp: '25',
                mp: '0',
                atk: '23',
                def: '16',
                mov: '6',
                spd: '23',
                spells: '',
                description: 'Attacks can apply Poison',
            },
            {
                name: 'Worm',
                img: WormSprite,
                weapon: '',
                hp: '17',
                mp: '0',
                atk: '20',
                def: '16',
                mov: '4',
                spd: '16',
                spells: '',
                description: 'Attacks can apply Sleep',
            },
            {
                name: 'Gargoyle',
                img: GargoyleSprite,
                weapon: '',
                hp: '18',
                mp: '20',
                atk: '26',
                def: '15',
                mov: '6',
                spd: '20',
                spells: 'Muddle 1',
                description: 'Attacks can apply Sleep',
            },
        ],
    },
    {
        id: 'Chapter6',
        title: 'Chapter 6',
        enemies: [
            {
                name: 'Artillery',
                img: ArtillerySprite,
                weapon: '',
                hp: '14',
                mp: '0',
                atk: '24',
                def: '15',
                mov: '4',
                spd: '14',
                spells: '',
                description: 'Range 2',
            },
            {
                name: 'Worm',
                img: WormSprite,
                weapon: '',
                hp: '17',
                mp: '0',
                atk: '20',
                def: '16',
                mov: '4',
                spd: '16',
                spells: '',
                description: '',
            },
            {
                name: 'Master Mage',
                img: MasterMageSprite,
                weapon: HolyStaff,
                hp: '22',
                mp: '32',
                atk: '33',
                def: '13',
                mov: '5',
                spd: '26',
                spells: 'Freeze 2',
                description: 'Regenerates 5 HP per turn',
            },
            {
                name: 'Gargoyle',
                img: GargoyleSprite,
                weapon: '',
                hp: '18',
                mp: '20',
                atk: '26',
                def: '15',
                mov: '6',
                spd: '20',
                spells: 'Muddle 1',
                description: 'Attacks can apply Sleep',
            },
            {
                name: 'Durahan',
                img: DurahanSprite,
                weapon: BroadSword,
                hp: '22',
                mp: '0',
                atk: '39',
                def: '28',
                mov: '5',
                spd: '13',
                spells: '',
                description: '',
            },
            {
                name: 'High Priest',
                img: HighPriestSprite,
                weapon: HolyStaff,
                hp: '20',
                mp: '33',
                atk: '13',
                def: '14',
                mov: '6',
                spd: '13',
                spells: 'Heal 4',
                description: '',
            },
            {
                name: 'Golem',
                img: GolemSprite,
                weapon: '',
                hp: '17',
                mp: '0',
                atk: '28',
                def: '28',
                mov: '4',
                spd: '16',
                spells: '',
                description: '',
            },
            {
                name: 'Cain',
                img: CainSprite,
                weapon: SwordOfDarkness,
                hp: '70',
                mp: '24',
                atk: '65',
                def: '30',
                mov: '6',
                spd: '29',
                spells: '',
                description: '',
            },
            {
                name: 'Bowrider',
                img: BowRiderSprite,
                weapon: AssaultShell,
                hp: '18',
                mp: '0',
                atk: '40',
                def: '11',
                mov: '7',
                spd: '14',
                spells: '',
                description: '',
            },
            {
                name: 'Belial',
                img: BelialSprite,
                weapon: '',
                hp: '21',
                mp: '35',
                atk: '26',
                def: '20',
                mov: '6',
                spd: '22',
                spells: 'Bolt 1',
                description: 'May occasionally steal MP instead',
            },
            {
                name: 'Mishaela',
                img: MishaelaSprite,
                weapon: '',
                hp: '65',
                mp: '??',
                atk: '42',
                def: '30',
                mov: '6',
                spd: '35',
                spells: 'Bolt 2',
                description: 'Boss Regeneration Script',
            },
        ],
    },
    {
        id: 'Chapter7',
        title: 'Chapter 7',
        enemies: [
            {
                name: 'Durahan',
                img: DurahanSprite,
                weapon: BroadSword,
                hp: '22',
                mp: '0',
                atk: '39',
                def: '28',
                mov: '5',
                spd: '13',
                spells: '',
                description: '',
            },
            {
                name: 'High Priest',
                img: HighPriestSprite,
                weapon: HolyStaff,
                hp: '20',
                mp: '33',
                atk: '13',
                def: '14',
                mov: '6',
                spd: '13',
                spells: 'Heal 4',
                description: '',
            },
            {
                name: 'Belial',
                img: BelialSprite,
                weapon: '',
                hp: '21',
                mp: '35',
                atk: '26',
                def: '20',
                mov: '6',
                spd: '22',
                spells: 'Bolt 1',
                description: 'May occasionally steal MP instead',
            },
            {
                name: 'Torch Eye',
                img: TorchEyeSprite,
                weapon: '',
                hp: '28',
                mp: '0',
                atk: '42',
                def: '32',
                mov: '6',
                spd: '27',
                spells: '',
                description: 'May attack with Laser',
            },
            {
                name: 'Minotaur',
                img: MinotaurSprite,
                weapon: '',
                hp: '31',
                mp: '0',
                atk: '46',
                def: '30',
                mov: '5',
                spd: '31',
                spells: '',
                description: '',
            },
            {
                name: 'Wyvern',
                img: WyvernSprite,
                weapon: '',
                hp: '32',
                mp: '0',
                atk: '39',
                def: '30',
                mov: '7',
                spd: '31',
                spells: '',
                description: 'May attack with Fire Breath',
            },
            {
                name: 'Jet',
                img: JetSprite,
                weapon: '',
                hp: '28',
                mp: '0',
                atk: '45',
                def: '32',
                mov: '7',
                spd: '33',
                spells: '',
                description: '',
            },
            {
                name: 'Ice Worm',
                img: IceWormSprite,
                weapon: '',
                hp: '30',
                mp: '12',
                atk: '40',
                def: '25',
                mov: '5',
                spd: '26',
                spells: '',
                description: 'May attack with Ice Breath, Attacks can apply Sleep',
            },
            {
                name: 'Steel Claw',
                img: SteelClawSprite,
                weapon: '',
                hp: '25',
                mp: '0',
                atk: '43',
                def: '31',
                mov: '5',
                spd: '31',
                spells: '',
                description: 'May attack with Laser',
            },
            {
                name: 'Demon Master',
                img: DemonMasterSprite,
                weapon: DemonRod,
                hp: '27',
                mp: '46',
                atk: '52',
                def: '24',
                mov: '6',
                spd: '50',
                spells: 'Freeze 3',
                description: '',
            },
            {
                name: 'Demon Master',
                img: DemonMasterSprite,
                weapon: '',
                hp: '27',
                mp: '46',
                atk: '52',
                def: '24',
                mov: '6',
                spd: '50',
                spells: 'Freeze 3',
                description: '',
            },
            {
                name: 'Demon Master',
                img: DemonMasterSprite,
                weapon: '',
                hp: '27',
                mp: '46',
                atk: '52',
                def: '24',
                mov: '6',
                spd: '50',
                spells: 'Muddle 3',
                description: 'Cannot use Muddle 3',
            },
            {
                name: 'Chaos',
                img: ChaosSprite,
                weapon: '',
                hp: '65',
                mp: '0',
                atk: '50',
                def: '35',
                mov: '6',
                spd: '32',
                spells: '',
                description: 'Boss Regeneration Script',
            },
            {
                name: 'Horseman',
                img: HorsemanSprite,
                weapon: BusterShot,
                hp: '24',
                mp: '0',
                atk: '53',
                def: '16',
                mov: '7',
                spd: '17',
                spells: '',
                description: '',
            },
            {
                name: 'Armed Skeleton',
                img: ArmedSkeletonSprite,
                weapon: GreatAxe,
                hp: '36',
                mp: '0',
                atk: '62',
                def: '33',
                mov: '5',
                spd: '32',
                spells: '',
                description: 'May attack with Machine Gun',
            },
            {
                name: 'Cerberus',
                img: CerberusSprite,
                weapon: '',
                hp: '27',
                mp: '0',
                atk: '42',
                def: '26',
                mov: '7',
                spd: '38',
                spells: '',
                description: 'May attack with Fire Breath',
            },
        ],
    },
    {
        id: 'Chapter8',
        title: 'Chapter 8',
        enemies: [
            {
                name: 'High Priest',
                img: HighPriestSprite,
                weapon: HolyStaff,
                hp: '20',
                mp: '33',
                atk: '13',
                def: '14',
                mov: '6',
                spd: '13',
                spells: 'Heal 4',
                description: '',
            },
            {
                name: 'Torch Eye',
                img: TorchEyeSprite,
                weapon: '',
                hp: '28',
                mp: '0',
                atk: '42',
                def: '32',
                mov: '6',
                spd: '27',
                spells: '',
                description: 'May attack with Laser Attack',
            },
            {
                name: 'Jet',
                img: JetSprite,
                weapon: '',
                hp: '28',
                mp: '0',
                atk: '45',
                def: '32',
                mov: '7',
                spd: '33',
                spells: '',
                description: '',
            },
            {
                name: 'Steel Claw',
                img: SteelClawSprite,
                weapon: '',
                hp: '25',
                mp: '0',
                atk: '43',
                def: '31',
                mov: '5',
                spd: '31',
                spells: '',
                description: 'May attack with Laser Attack',
            },
            {
                name: 'Armed Skeleton',
                img: ArmedSkeletonSprite,
                weapon: GreatAxe,
                hp: '36',
                mp: '0',
                atk: '62',
                def: '33',
                mov: '5',
                spd: '32',
                spells: '',
                description: 'May attack with Machine Gun',
            },
            {
                name: 'Horseman',
                img: HorsemanSprite,
                weapon: BusterShot,
                hp: '24',
                mp: '0',
                atk: '53',
                def: '16',
                mov: '7',
                spd: '17',
                spells: '',
                description: '',
            },
            {
                name: 'Cerberus',
                img: CerberusSprite,
                weapon: '',
                hp: '27',
                mp: '0',
                atk: '42',
                def: '26',
                mov: '7',
                spd: '38',
                spells: '',
                description: 'May attack with Fire Breath',
            },
            {
                name: 'Chimaera',
                img: ChimeraSprite,
                weapon: '',
                hp: '56',
                mp: '0',
                atk: '65',
                def: '30',
                mov: '6',
                spd: '40',
                spells: '',
                description: 'May attack with Fire Breath',
            },
            {
                name: 'Blue Dragon',
                img: BlueDragonSprite,
                weapon: '',
                hp: '50',
                mp: '0',
                atk: '63',
                def: '32',
                mov: '5',
                spd: '42',
                spells: '',
                description: 'May attack with Ice Breath',
            },
            {
                name: 'Ramladu',
                img: RamladuSprite,
                weapon: HolyStaff,
                hp: '99',
                mp: '??',
                atk: '93',
                def: '40',
                mov: '6',
                spd: '49',
                spells: 'Aura 3',
                description: 'Boss Regeneration Script',
            },
            {
                name: 'Colossus (R)',
                img: ColossusSprite,
                weapon: '',
                hp: '65',
                mp: '??',
                atk: '50',
                def: '40',
                mov: '4',
                spd: '37',
                spells: 'Blaze 3',
                description: '',
            },
            {
                name: 'Colossus',
                img: ColossusSprite,
                weapon: '',
                hp: '65',
                mp: '??',
                atk: '50',
                def: '40',
                mov: '4',
                spd: '37',
                spells: 'Bolt 3',
                description: '',
            },
            {
                name: 'Colossus (L)',
                img: ColossusSprite,
                weapon: '',
                hp: '65',
                mp: '??',
                atk: '50',
                def: '40',
                mov: '4',
                spd: '37',
                spells: 'Freeze 3',
                description: '',
            },
            {
                name: 'Darksol',
                img: DarksolSprite,
                weapon: '',
                hp: '150',
                mp: '??',
                atk: '40',
                def: '35',
                mov: '6',
                spd: '54',
                spells: '',
                description: 'May attack with Demon Breath',
            },
            {
                name: 'Dark Dragon (L)',
                img: DarkDragonSprite,
                weapon: '',
                hp: '225',
                mp: '??',
                atk: '67',
                def: '40',
                mov: '0',
                spd: '52',
                spells: '',
                description: '',
            },
            {
                name: 'Dark Dragon',
                img: DarkDragonSprite,
                weapon: '',
                hp: '275',
                mp: '??',
                atk: '65',
                def: '40',
                mov: '0',
                spd: '60',
                spells: '',
                description: 'May attack with Demon Breath',
            },
            {
                name: 'Dark Dragon (R)',
                img: DarkDragonSprite,
                weapon: '',
                hp: '225',
                mp: '??',
                atk: '67',
                def: '40',
                mov: '0',
                spd: '52',
                spells: '',
                description: '',
            },
        ],
    },
];
