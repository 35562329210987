import React, { ReactElement } from 'react';

import Container from '../components/common/container';
import SpellArt from '../assets/sf1/spells_art.png';
import SpellTable from '../components/spells/spells-table';
import GuideBlock from '../components/common/guide-block';
import { GuideType } from '../types/enums/collections';

export default function SpellPage(): ReactElement {
    return (
        <div>
            <div className='mb-8 flex flex-col gap-8 lg:flex-row'>
                <div className='w-full lg:w-2/5'>
                    <Container title='Spells in Shining Force 1'>
                        <p className='text-dark-font'>
                            You can see a complete list of the spells in Shining Force 1 down below or head into
                            community written guides to see more particular info!
                        </p>
                        <div className='px-8 pt-4'>
                            <img className='sf-border w-full' src={SpellArt} alt='Max counting Dark Dwarves' />
                        </div>
                    </Container>
                </div>
                <div className='w-full lg:w-3/5'>
                    <Container disablePadding title='Guides related to Spells'>
                        <GuideBlock type={GuideType.GENERAL} topic='Spells' />
                    </Container>
                </div>
            </div>
            <div className='mx-auto w-full xl:w-4/5'>
                <Container disablePadding title='Spells in Shining Force 1'>
                    <p className='text-center font-bold text-white lg:hidden'>Swipe horizontally</p>
                    <SpellTable />
                </Container>
            </div>
        </div>
    );
}
