import React, { ReactElement } from 'react';
import Header from './header/header';
import Footer from './footer';
import BackButton from '../common/back-button';
import { useLocation } from 'react-router-dom';

type Props = {
    children: ReactElement | ReactElement[];
};

export default function CommonLayout(props: Props): ReactElement {
    const { children } = props;

    const location = useLocation();

    return (
        <div className='bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-sf-blue-dark to-black'>
            <div className='relative w-full max-w-[768px] px-4 lg:mx-auto lg:w-[960px] lg:max-w-none lg:p-0 xl:w-[1200px]'>
                <div className='bg-grid-gradient absolute left-0 top-0 z-0 h-full w-full bg-grid opacity-10' />
                <div className='relative z-10 min-h-screen'>
                    <Header />
                    {location.pathname === '/' ? (
                        children
                    ) : (
                        <>
                            <BackButton />

                            <div className='-mt-[69px]'>{children}</div>
                        </>
                    )}

                    <div className='mx-auto mt-16 h-[2px] w-full bg-gradient-to-r from-transparent via-sf-blue-medium to-transparent' />
                </div>
            </div>
            <Footer />
        </div>
    );
}
