import React, { ReactElement, useEffect } from 'react';
import { SF1Spells } from '../../data/spells';
import { AreaLevel, RangeLevel } from '../../types/enums/spells';

import RangeOneIndicator from '../../assets/sf1/range/range-1.png';
import RangeOneToTwoIndicator from '../../assets/sf1/range/range-1-2.png';
import RangeTwoIndicator from '../../assets/sf1/range/range-2.png';
import RangeOneToThreeIndicator from '../../assets/sf1/range/range-1-3.png';
import RangeTwoToThreeIndicator from '../../assets/sf1/range/range-2-3.png';
import RangeSelfIndicator from '../../assets/sf1/range/range-self.png';
import AreaOneIndicator from '../../assets/sf1/range/area-1.png';
import AreaFiveIndicator from '../../assets/sf1/range/area-5.png';
import AreaFourteenIndicator from '../../assets/sf1/range/area-14.png';

export default function SpellTable(): ReactElement {
    function renderAreaGraphic(area: AreaLevel): string {
        switch (area) {
            case AreaLevel.ONE:
                return AreaOneIndicator;
            case AreaLevel.FIVE:
                return AreaFiveIndicator;
            case AreaLevel.FOURTEEN:
                return AreaFourteenIndicator;
            case AreaLevel.ALL:
                return AreaOneIndicator;
            default:
                return 'No Area';
        }
    }

    function renderRangeGraphic(range: RangeLevel): string {
        switch (range) {
            case RangeLevel.ONE:
                return RangeOneIndicator;
            case RangeLevel.ONETOTWO:
                return RangeOneToTwoIndicator;
            case RangeLevel.ONETOTHREE:
                return RangeOneToThreeIndicator;
            case RangeLevel.TWO:
                return RangeTwoIndicator;
            case RangeLevel.TWOTOTHREE:
                return RangeTwoToThreeIndicator;
            case RangeLevel.SELF:
                return RangeSelfIndicator;
            default:
                return 'No Range';
        }
    }

    return (
        <>
            {SF1Spells.map((spell) => (
                <>
                    <div className='flex w-full flex-row items-center border-t border-sf-blue-highlight bg-sf-blue-bright-transparent py-2'>
                        <img className='mx-3 w-7' src={spell.icon} alt='Spell icon' />
                        <p className='font-title text-white'>{spell.name}</p>
                    </div>
                    <div className='overflow-x-scroll lg:overflow-x-auto '>
                        <table className='w-[800px] lg:w-full'>
                            <tr className='text-center text-dark-font'>
                                <th className='w-12 bg-sf-blue-bright-transparent'>Level</th>
                                <th className='w-12 bg-sf-blue-dark '>Cost</th>
                                <th className='w-24 bg-sf-blue-dark '>Range</th>
                                <th className='w-24 bg-sf-blue-dark '>Area of Effect</th>
                                <th className='w-48 bg-sf-blue-dark '>Learned by:</th>
                                <th className='bg-sf-blue-dark'>Description</th>
                            </tr>

                            {spell.level.map((level) => (
                                <tr className='h-14 border-b border-sf-blue-bright-transparent text-white'>
                                    <td className='bg-sf-blue-bright-transparent text-center font-title'>
                                        {level.level}
                                    </td>
                                    <td className='text-center'>{level.cost}</td>
                                    <td>
                                        <img
                                            className='mx-auto w-10'
                                            src={renderRangeGraphic(level.range)}
                                            alt='Range Indicator'
                                        />
                                    </td>
                                    <td>
                                        <img
                                            className='mx-auto w-14'
                                            src={renderAreaGraphic(level.area)}
                                            alt='Area Indicator'
                                        />
                                    </td>
                                    <td className='py-1'>
                                        {level.characters === undefined ? (
                                            <p className='text-center'>Nobody</p>
                                        ) : (
                                            <div className='flex h-full flex-row justify-center gap-2 align-middle'>
                                                {level.characters.map((character) => (
                                                    <div className='flex flex-col items-center'>
                                                        <img
                                                            className='sf-border-small w-8'
                                                            src={character.icon}
                                                            alt='character portrait'
                                                        />
                                                        <p className='font-menu text-xs text-white'>
                                                            {character.level}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </td>
                                    <td>{level.description}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </>
            ))}
        </>
    );
}
